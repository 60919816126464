import { SET_APPLICATION_LANGUAGE } from "../actions";


export default (state = { language: 'en' }, action) => {
    switch (action.type) {
        case SET_APPLICATION_LANGUAGE:
            return { ...state, language: action.language };
        default:
            return state;
    }
};
