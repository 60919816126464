import React from 'react';
import { withStyles } from '@material-ui/core';
import hiaLogo from '../../images/logoHIAsecure.png'
import { Content } from '../../widgets';
import Acknowledgment from '../../components/Acknowledgment';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import * as conventionActions from '../../actions/convention';
import * as componentActions from '../../actions';
import * as tutorialActions from '../../actions/tutorial';
import { getConvention, getLoading, getAcknowledgeStatus, getUrlError } from '../../selectors/conventions';
import withLoading from '../../hoc/withLoading';
import NavigationPrompt from 'react-router-navigation-prompt';
import ConfirmNavigation from '../../widgets/ConfirmNavigation';
import { withRouter } from 'react-router-dom';
import withRoutes from '../../hoc/routes';
import { getTutorialToken } from '../../selectors/tutorial';
import hiaLogoWhite from '../../images/logoHIAsecure_blanc.png'





export const styles = (theme) => ({
    bigContainer: {
        display: 'block',
        height: '60px !important'
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    title: {
        marginLeft: '60px',
        fontWeight: 600,
        color: '#28558a',
        fontSize: '2.125rem',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.725rem',
        },


    },

    image: {
        [theme.breakpoints.down('sm')]: {
            width: '50px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '120px',
        },
    }

})

const Page = ({ classes, convention, actions, acknowledgeStatus, match, tutorialToken }) => {
    return (
        <Grid container >
            <NavigationPrompt when={acknowledgeStatus === false}>
                {({ onConfirm, onCancel }) => <ConfirmNavigation onCancel={onCancel} onConfirm={onConfirm} />}
            </NavigationPrompt>
            <Grid container className={classes.header}  >
                <Grid item xs={12} >
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container justify="center" alignItems="center">
                                <img src={acknowledgeStatus ? hiaLogoWhite : hiaLogo} className={classes.image} alt="logo HIA" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Content>
                {convention && <Acknowledgment tutorialToken={tutorialToken} match={match} convention={convention} actions={actions} acknowledgeStatus={acknowledgeStatus} />}
            </Content>
        </Grid>
    )
}

const actions = {

    getConvention: conventionActions.loadConventionByHash,
    setLoadingtrue: componentActions.setLoadingTrue,
    setLanguage: componentActions.setApplicationLanguage,
    acknowledgeOfReceipt: conventionActions.acknowledgeOfReceipt,
    setAcknowledgeStatus: conventionActions.setAcknowledgeStatus,
    getTutorialFaqUrl: tutorialActions.getTutorialUrl

};

const mapStateToProps = createStructuredSelector({
    convention: getConvention,
    isLoading: getLoading,
    acknowledgeStatus: getAcknowledgeStatus,
    urlError: getUrlError,
    tutorialToken: getTutorialToken
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const languagesList = ['en', 'fr']
const loadComponent = lifecycle({
    componentDidMount() {
        const { actions, match } = this.props
        

        actions.setLanguage(languagesList.includes(match.params.language)?match.params.language : 'en')
        actions.setLoadingtrue();
        actions.setAcknowledgeStatus(false)
        actions.getConvention(match.params.id, languagesList.includes(match.params.language)?match.params.language : 'en');

    },
    componentWillReceiveProps(nextProps) {
        const { urlError, history, acknowledgeStatus, match, actions } = this.props;
        if (nextProps.urlError !== urlError) {
            history.push('/ackOfreceipt/errorPage')
        }
        if (nextProps.acknowledgeStatus !== acknowledgeStatus && nextProps.acknowledgeStatus === true) {
            actions.getTutorialFaqUrl(match.params.id)

        }


    },
    

});

export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withRoutes,
    loadComponent,
    withLoading,

);
export default enhance(Page);