import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import hiaLogo from '../../images/logoHIAsecure.png'
import { Content } from '../../widgets';
import { withRouter } from 'react-router-dom';
import SessionFinished from '../../components/SessionFinished';

const styles = (theme) => ({
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    title: {
        marginLeft: '60px',
        fontWeight: 600,
        color: '#28558a',
        fontSize: '2.125rem',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.725rem',
        },


    },

    image: {
        [theme.breakpoints.down('sm')]: {
            width: '50px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '120px',
        },
    }
});

const Page = ({ classes }) => {
    return (
        <Grid container>
          <Grid container className={classes.header}  >
                <Grid item xs={12} >
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container justify="center" alignItems="center">
                                <img src={ hiaLogo} className={classes.image} alt="logo HIA" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.title}>
                            <FormattedMessage id="session.title" />
                            </Typography>
                        </Grid>
                      
                    </Grid>
                </Grid>
            </Grid>
      
            <Content>
                <SessionFinished />
            </Content>
        </Grid>
    )
}


export const enhance = compose(
    withStyles(styles),
    withRouter);
export default enhance(Page);