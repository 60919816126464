import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { Content } from '../../widgets';
import Home from '../../components/Home';
import withRoutes from '../../hoc/routes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as clientActions from '../../actions/clients';
import { getClientList, getClientsTypeStats } from '../../selectors/clients';
import * as reportsActions from '../../actions/reports';
import { getDashboardStats } from '../../selectors/reports';


const Page = ({ routes, location, actions, clientList, clientsTypeStats,dashboardStats }) => {
    const route = routes.getRouteByPath(location.pathname);
    const isAuthRequired = routes.isAuthRequired(route)

    return (
        <Grid container>
            <Content>
                <Home isAuthRequired={isAuthRequired} actions={actions} clientList={clientList} 
                clientsTypeStats={clientsTypeStats} dashboardStats={dashboardStats} />
            </Content>
        </Grid>
    )
}
const actions = {
    getClientList: clientActions.getClientList,
    getClientsTypeStats: clientActions.getClientsTypeStats,
    setClient: clientActions.setClient,
    getDashboardStatistics:reportsActions.getDashboardStatistics
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    clientList: getClientList,
    clientsTypeStats: getClientsTypeStats,
    dashboardStats:getDashboardStats

})
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes)
export default enhance(Page)