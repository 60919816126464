import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

const styles = theme => ({

  notchedOutline: {
    borderWidth: "0px",
    borderColor: "transparent !important"
  },
  title: {
    margin: '0 auto',
  },
});
const TextFieldForm = ({
  input: { value, onChange, name, ...restInput },
  meta: { error, touched },
  readOnly,
  classes,
  focus,
  width='100%',
  ...rest
}) => {
  return (
    <FormControl style={{ width: width }} >
      <TextField

        {...rest}
        name={name}
        value={value}
        onChange={onChange}
        helperText={touched ? error : undefined}
        margin="dense"
        variant="outlined"
        InputProps={{
          readOnly: readOnly,
          autoFocus: focus,
          classes: {
            notchedOutline: readOnly && classes.notchedOutline
          }
        }}
      
      />
    </FormControl>
  )
};


export default withStyles(styles)(TextFieldForm);