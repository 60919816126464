import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import UsersTable from './UsersTable';
import Facets from './Facets';

const styles = (theme) => ({
    secondButton: {
        backgroundColor: '#d9d9d9',
        color: 'black',
        fontSize: '16px',
        width: 150,
        border: '1px solid #d9d9d9',
        textTransform: 'none',
        marginLeft: theme.spacing.unit * 2,
        height: 47,
        '&:hover': {
            backgroundColor: '#d9d9d9',

        }

    },
})

const Component = ({ anchor, menu, toggleMenu, toggleSort, sort, clientUsers, toggleTerm, toggleFacetFilter,
    togglePage, toggleRows, page, rowsPerPage, usersCount, toggleFilter, searchType, searchLevel }) => {
    return (
        <Grid container style={{ marginTop: '1%' }}>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={3}>
                        <SearchBar
                            onChange={(event) => toggleTerm(event)}
                            onCancelSearch={() => toggleTerm('')}
                            style={{
                                border: '1px solid rgba(45,56,130,1)',
                                width: "90%"
                            }}
                        />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={3}>
                        <Facets toggleFacetFilter={toggleFacetFilter} usersCount={usersCount} />
                    </Grid>
                    <Grid item xs={9}>
                        <UsersTable toggleMenu={toggleMenu} toggleSort={toggleSort} menu={menu} sort={sort} anchor={anchor}
                            clientUsers={clientUsers} togglePage={togglePage} toggleRows={toggleRows} page={page}
                            rowsPerPage={rowsPerPage} toggleFilter={toggleFilter} searchType={searchType} searchLevel={searchLevel}  />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
const withColumnMenu = withStateHandlers(() => ({ anchor: undefined, menu: undefined }), {
    toggleMenu: () => (value1, value2) => ({ anchor: value1, menu: value2 }),
});
const withSort = withStateHandlers(() => ({ sort: [] }), {
    toggleSort: () => value => ({ sort: value }),
});
const withSearchTerm = withStateHandlers(() => ({ term: '' }), {
    toggleTerm: () => value => ({ term: value }),
});
const withFacetFilter = withStateHandlers(() => ({ accountStatus: '', conventionStatus: '' }), {
    toggleFacetFilter: () => (value1, value2) => ({ accountStatus: value1, conventionStatus: value2 }),
});
const withRows = withStateHandlers(() => ({ rowsPerPage: 5 }), {
    toggleRows: () => event => ({ rowsPerPage: event.target.value }),
});
const withPage = withStateHandlers(() => ({ page: 0 }), {
    togglePage: () => (event, page) => ({ page: page }),
});
const withFilter = withStateHandlers(() => ({ searchType: '', searchLevel: '' }), {
    toggleFilter: () => (value1, value2) => ({ searchType: value1, searchLevel: value2 }),
});

const loadComponents = lifecycle({

    componentWillMount() {
        const { actions, client, isAuthRequired, term, accountStatus, conventionStatus, page, rowsPerPage, searchType, searchLevel, sort } = this.props
        actions.getClientUsersList(client.id, term, accountStatus, conventionStatus,searchType, searchLevel, sort, page, rowsPerPage, isAuthRequired)

    },
    componentWillReceiveProps(nextProps) {
        const { actions, client, term, accountStatus, conventionStatus, page, rowsPerPage, isAuthRequired,searchType, searchLevel, sort } = this.props
        if (nextProps.term !== term || nextProps.accountStatus !== accountStatus ||
            nextProps.conventionStatus !== conventionStatus || nextProps.page !== page || nextProps.rowsPerPage !== rowsPerPage||
            nextProps.searchType !== searchType || nextProps.searchLevel !== searchLevel || nextProps.sort !== sort ) {

            if (nextProps.rowsPerPage * nextProps.clientUsers.number > nextProps.clientUsers.totalElements) {
                actions.getClientUsersList(client.id, nextProps.term, nextProps.accountStatus, nextProps.conventionStatus,nextProps.searchType, nextProps.searchLevel, nextProps.sort, Math.ceil(nextProps.clientUsers.totalElements / nextProps.rowsPerPage) - 1, nextProps.rowsPerPage, isAuthRequired)

            }

            else {
                actions.getClientUsersList(client.id, nextProps.term, nextProps.accountStatus, nextProps.conventionStatus,nextProps.searchType, nextProps.searchLevel,nextProps.sort, nextProps.page, nextProps.rowsPerPage, isAuthRequired)
            }



        }
    }

},
);
export const enhance = compose(
    withStyles(styles),
    withColumnMenu,
    withSort,
    withSearchTerm,
    withFacetFilter,
    withPage,
    withRows,
    withFilter,
    loadComponents)
export default enhance(Component)