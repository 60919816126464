import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import { type, alertType } from '../utils/select';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import { find, findIndex, propEq, remove } from 'ramda';

const styles = () => ({
    menu: {
        backgroundColor: "#bfbfbf",
        width: 150
    },
    selectedMenu: {
        backgroundColor: "white",
        width: 150
    }
})
const Component = ({ anchorEl, toggleMenu, classes, menu, cursor, handleKey, togglePage, toggleFilter, toggleAlertType }) => {

    const handleClick = (id, type) => {
        const typeExist = find(propEq('type', type))(cursor)
        if (typeExist === undefined) {
            const newSelected = cursor.concat({ 'id': id, 'type': type });
            handleKey(newSelected)
        }
        else {
            const index = findIndex(propEq('type', type))(cursor);
            let newSelected = remove(index, 1, cursor)
            if (typeExist.id !== id) {
                newSelected = newSelected.concat({ 'id': id, 'type': type });

            }
            handleKey(newSelected)
        }
    }

    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}

            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => toggleMenu()}

        >

            {menu === 'type' ?
                type.map((element, i) => {
                    const inTable = find(element => element.id === i && element.type === menu)(cursor)
                    return (
                        <MenuItem style={{ zIndex: 20 }} key={element.value} className={inTable ? classes.selectedMenu : classes.menu}
                            onClick={(event) => {
                                toggleMenu();
                                inTable ? toggleFilter('', '') : toggleFilter('', element.value);
                                handleClick(i, menu)
                                togglePage(event, 0)

                            }}
                        >
                            <Grid item xs={12}>
                                <Grid container alignItems="center" >
                                    <Grid item xs={9}>
                                        <Grid container justify="flex-start">
                                            <Typography >
                                                {element.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container justify='flex-end' >
                                            {inTable &&
                                                <Close />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MenuItem>)
                })

                : alertType.map((element, i) => {
                    const inTable = find(element => element.id === i && element.type === menu)(cursor)
                    return (
                        <MenuItem style={{ zIndex: 20 }} key={element.value} className={inTable ? classes.selectedMenu : classes.menu}
                            onClick={(event) => {
                                toggleMenu();
                                handleClick(i, menu)
                                toggleAlertType(inTable ? '' : element.value)

                            }}
                        >
                            <Grid item xs={12}>
                                <Grid container alignItems="center" >
                                    <Grid item xs={9}>
                                        <Grid container justify="flex-start">
                                            <Typography >
                                                {element.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container justify='flex-end' >
                                            {inTable &&
                                                <Close />
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MenuItem>)
                })}
        </Popover>
    )
}

const withKey = withStateHandlers(() => ({ cursor: [], }), {
    handleKey: () => (value1) => ({ cursor: value1 }),
})

export const enhance = compose(
    withStyles(styles),
    withKey)
export default enhance(Component)