import { find, reduce, sum, compose, values, propEq, pick, groupBy, keys, sort, map, uniq, findIndex, move, innerJoin, contains } from 'ramda';
import { formatKpiDate } from './date';
import { level } from '../widgets/ClientUsersCollumnMenu';
import { userType, alertStatus } from './select';


export const getEmailContent = (code, language, list) => list && find(element => (element.code === code && element.language.toUpperCase() === language.toUpperCase()))(list)

export const getElementCount = (list, type, status, defaultValue) => {
    if (list !== undefined) {
        if (type === '' && status === '') {
            return sum(reduce((acc, element) => [...acc, element.count], [])(list[defaultValue]))
        }
        else {
            let itExist = compose(find(propEq(type, status)))(list[type])
            if (itExist !== undefined) {
                return (
                    compose(values, pick(['count']))(itExist)
                )
            }
            else return 0

        }
    }

}

export const getLabel = (element, object) => {
    if (element) {
        const Test = compose(values, pick(['label']), find(propEq('value', element)))(object)
        return Test[0]
    }


}

export const getUsersElementCount = (list, type, status, defaultValue) => {
    if (list !== undefined) {
        if (type === '' && status === '') {
            return sum(reduce((acc, element) => [...acc, element.count], [])(list[defaultValue]))
        }
        else {
            let test = compose(find(propEq(`${type}Status`, status)))(list[`${type}StatusStats`])
            if (test !== undefined) {
                return (
                    compose(values, pick(['count']))(test)
                )
            }
            else return 0

        }
    }

}
export const isSerieOrLevel = (element, list) => contains(element, map(element => element.value)(list));

export const getDataSets = (data, filter, unit) => {
    if (data) {
        const byDate = groupBy(function (elem) {
            return elem.date;
        });
        const bySerie = groupBy(function (elem) {
            return elem.serie;
        });
        const colors = ['#a9b2be', '#889dbe', '#4a6d96', '#0d3d77', '#28558a']
        const series = compose(keys, bySerie)(data)
        console.log('series', series)

        const sortedSeries = filter === 'type' ?
            sort(diff(typeWeight), series) :
            filter === 'level' ? sort(diff(levelWeight), series) :
                series;


        const dates = compose(values, byDate)(data)
        return sortedSeries.map((serie, index) => {
            const data = map((ele) => {
                const count = compose(find(propEq('serie', serie)))(ele)
                return count && (unit === 'failed' || unit === 'blocked') ?
                    Math.round(count.count * 100 / count.total) :
                    count && (unit === 'conventions' || unit === 'connections') ?
                        count.count
                        : null;
            })(dates)
            return {
                label: filter === 'all' ? 'All' : filter === 'level' ? isSerieOrLevel(serie, level) ? getLabel(serie, level) : '' : isSerieOrLevel(serie, userType) ? getLabel(serie, userType) : '',
                data: data,
                backgroundColor: filter === 'all' ? colors[4] : colors[index]
            }
        });
    }
    return []
}
const typeWeight = {
    'employee': 1,
    'temp': 2,
    'client': 3,
    'supplier': 4,
    'null': 5
}
const levelWeight = {
    'LEVEL_1': 1,
    'LEVEL_2': 2,
    'LEVEL_3': 3,
}
export const diff = (tableOfwieght) => function (a, b) { return tableOfwieght[a] - tableOfwieght[b]; };

export const getDatasetsLabel = (data) => data && compose(uniq, map(element => formatKpiDate(element.date)))(data)

export const getAlertsCount = (list, value) => {
    if (list !== undefined) {
        if (value === '') {
            return sum(reduce((acc, element) => [...acc, element.count], [])(list))
        }
        else {
            let itExist = compose(find(propEq('status', value)))(list)
            if (itExist !== undefined) {
                return (
                    compose(values, pick(['count']))(itExist)
                )
            }
            else return 0

        }
    }

}
export const itSortExist = (type, direction, sort) => find(element => element.type === type && element.direction === direction)(sort)

export const onSortClick = (type, direction, action) => {
    action([{ 'type': type, 'direction': direction }])

}

export const displayAlertNote = (note) => {
    if (note && note.length >= 150) {
        return note.substr(0, 150).concat(' ...')
    }
    return note
}

export const getAvailableStatus = (element) => {

    switch (element) {

        case 'CLOSED': {
            return innerJoin(
                (record, id) => record.value === id,
                alertStatus,
                ['CLOSED', 'PENDING']
            )
        }
        default:
            return alertStatus
    }

}
const getIndex = (element) => findIndex(propEq('value', element))(getAvailableStatus(element))
export const sortStatusList = (element) => move(getIndex(element), 0, getAvailableStatus(element));

export const isInRole = (role, object) => contains(role, object);
