import React from 'react';
import Grid from '@material-ui/core/Grid'
import { compose } from 'recompose';
import { Field } from 'react-final-form';
import Select from '../../../forms/Select';
import TextField from '../../../forms/UsedTextField';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormContainer from '../../../widgets/FormContainer';
import { type, country, language } from '../../../utils/select';
import { Typography } from '@material-ui/core';


const Component = ({ intl, editMode, client, values }) => {
    return (
        <Grid container >
            <FormContainer title={<FormattedMessage id="client.company" />}>

                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={TextField}
                            name="code"
                            type="text"
                            label={`${intl.formatMessage({ id: "client.company.id" })} *`}
                            width='90%'
                            readOnly={!editMode}


                        />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={TextField}
                            name="name"
                            type="text"
                            label={`${intl.formatMessage({ id: "client.company.name" })} *`}
                            width='90%'
                            readOnly={!editMode}


                        />
                    </Grid>
                </Grid>


            </FormContainer>
            <FormContainer title={<FormattedMessage id="client.type.type" />}>
                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={TextField}
                            name="industry"
                            type="text"
                            label={intl.formatMessage({ id: "client.type.industry" })}
                            width='90%'
                            readOnly={!editMode}

                        />
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={Select}
                            name="type"
                            type="select"
                            label={intl.formatMessage({ id: "client.type.type" })}
                            object={type}
                            width='90%'
                            readOnly={!editMode}

                        />
                    </Grid>
                </Grid>
            </FormContainer>
            <FormContainer title={<FormattedMessage id="client.location" />}>
                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={Select}
                            name="country"
                            type="select"
                            label={`${intl.formatMessage({ id: "client.location.country" })} *`}
                            object={country}
                            withCountry={true}
                            width='90%'
                            readOnly={!editMode}
                            client={client}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={Select}
                            name="language"
                            type="select"
                            label={intl.formatMessage({ id: "client.location.language" })}
                            object={language}
                            width='90%'
                            readOnly={!editMode}

                        />
                    </Grid>
                </Grid>
            </FormContainer>
            <FormContainer title={<FormattedMessage id="client.date" />}>
                <Grid item xs={4}>
                    <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={TextField}
                            name="contratDate"
                            type="text"
                            label={<FormattedMessage id="client.date.openDate" />}
                            readOnly={true}
                            width='90%'

                        />
                    </Grid>
                </Grid>

            </FormContainer>
            <FormContainer title={<FormattedMessage id="client.note.note" />}>
                <Grid item xs={4}>
                {editMode?  <Grid container justify="center" alignItems="flex-end">
                        <Field
                            component={TextField}
                            name="note"
                            label={!values.note && intl.formatMessage({ id: "client.note.note" })}
                            width='90%'
                            multiline
                            rowsMax={5}
                            readOnly={!editMode}
                            spellCheck={false}
                        />
                    </Grid> :
                        <Grid container justify="flex-start" alignItems="flex-end" style={{ marginLeft: '8%' }}>
                        {console.log('values', values.note)}
                        <Typography style={{ color: '#5c7086', fontSize: 15 }} dangerouslySetInnerHTML={{ __html: values.note && values.note }} />
                    </Grid>}
                </Grid>

            </FormContainer>
        </Grid>

    )
}

export const enhance = compose(injectIntl);
export default enhance(Component);