import React from 'react';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Home from '@material-ui/icons/Home';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import { FormattedMessage } from 'react-intl';
import Kpi from './Kpi';
import Button from '@material-ui/core/Button';
import SearchBar from 'material-ui-search-bar'
import Facets from './Facets';
import ClientTable from './ClientTable';
import withRoutes from '../../hoc/routes';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    content: {
        marginLeft: theme.spacing.unit * 2
    },
    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        width: '90%',
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',
        height: 47

    },
    secondButton: {
        backgroundColor: '#d9d9d9',
        color: 'black',
        fontSize: '16px',
        width: '90%',
        border: '1px solid #d9d9d9',
        textTransform: 'none',
        height: 47,
        '&:hover': {
            backgroundColor: '#d9d9d9',

        }

    },
})

const Component = ({ classes, isKPIShowed, toggleKpi, toggleMenu, anchor, menu, toggleSort, sort, routes, clientList,
    clientsTypeStats, toggleSearch, toggleFacetFilter, filterStatus, filterType, toggleRows, togglePage, rowsPerPage, page, dashboardStats }) => {
    return (
        <Grid container>
            <Grid item xs={10}>
                <Grid container className={classes.header}>
                    <Grid item xs={2}>
                        <Grid container justify="flex-start" alignItems="flex-end"  >
                            <Home className={classes.icon} />
                            <Spacer size="MEDIUM" />
                            <Title text={<FormattedMessage id="client.dashboard" />} />
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Grid container justify="flex-start" alignItems="flex-end">
                                    <IconButton onClick={() => toggleKpi(!isKPIShowed)}>
                                        {isKPIShowed ? <ArrowDropUp style={{ color: "black", fontSize: 30 }} /> :
                                            <ArrowDropDown style={{ color: "black", fontSize: 30 }} />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid />
                </Grid>
            </Grid>
            {isKPIShowed && <Kpi dashboardStats={dashboardStats} />}
            <Grid container className={classes.content} >

                <Grid item xs={10}>
                    <Grid container justify="flex-start" alignItems="center" >
                        <Grid item xs={3}>
                            <Grid container justify="flex-start">

                                <SearchBar
                                    onChange={(event) => toggleSearch(event)}
                                    onCancelSearch={() => toggleSearch('')}
                                    style={{
                                        border: '1px solid #4a7ebb',
                                        width: '80%'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                        
                            <Grid container justify="flex-start">
                                <Button
                                    component={Link}
                                    to={routes.getPathByName('AddClient')}
                                    target="_blank"
                                    className={classes.button}>
                                    + add client
                        </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <Grid container justify="flex-start">
                                <Button disabled={true} className={classes.secondButton}>export csv</Button>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Grid container  >
                        <Grid item xs={3}>
                            <Facets clientsTypeStats={clientsTypeStats} toggleFacetFilter={toggleFacetFilter} filterStatus={filterStatus} filterType={filterType} />
                        </Grid>
                        <Grid item xs={9}>
                            <ClientTable toggleMenu={toggleMenu} anchor={anchor} menu={menu} toggleSort={toggleSort} sort={sort}
                                clientList={clientList} toggleRows={toggleRows} togglePage={togglePage} rowsPerPage={rowsPerPage}
                                page={page} toggleFilter={toggleFacetFilter} />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}
const withKPI = withStateHandlers(() => ({ isKPIShowed: false }), {
    toggleKpi: () => value => ({ isKPIShowed: value }),
});
const withColumnMenu = withStateHandlers(() => ({ anchor: undefined, menu: undefined }), {
    toggleMenu: () => (value1, value2) => ({ anchor: value1, menu: value2 }),
});
const withSort = withStateHandlers(() => ({ sort: [] }), {
    toggleSort: () => value => ({ sort: value }),
});
const withSearchTerm = withStateHandlers(() => ({ searchTerm: '' }), {
    toggleSearch: () => value => ({ searchTerm: value }),
});
const withFacetFilter = withStateHandlers(() => ({ filterType: '', filterStatus: '' }), {
    toggleFacetFilter: () => (value1, value2) => ({ filterStatus: value1, filterType: value2 }),
});
const withRows = withStateHandlers(() => ({ rowsPerPage: 10 }), {
    toggleRows: () => event => ({ rowsPerPage: event.target.value }),
});
const withPage = withStateHandlers(() => ({ page: 0 }), {
    togglePage: () => (event, page) => ({ page: page }),
});

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired, searchTerm, filterStatus, filterType, page, rowsPerPage, sort } = this.props
        actions.getClientList(searchTerm, filterStatus, filterType, page, rowsPerPage, sort, isAuthRequired)
        actions.setClient()
        actions.getDashboardStatistics(isAuthRequired)

    },
    componentWillReceiveProps(nextProps) {
        const { actions, isAuthRequired, searchTerm, filterStatus, filterType, page, rowsPerPage, sort } = this.props

        if ((nextProps.searchTerm !== searchTerm) || (nextProps.filterStatus !== filterStatus) || (nextProps.filterType !== filterType) || nextProps.page !== page || nextProps.rowsPerPage !== rowsPerPage || nextProps.sort !== sort) {
            if (nextProps.rowsPerPage * nextProps.clientList.number > nextProps.clientList.totalElements) {
                actions.getClientList(nextProps.searchTerm, nextProps.filterStatus, nextProps.filterType, Math.ceil(nextProps.clientList.totalElements / nextProps.rowsPerPage) - 1, nextProps.rowsPerPage, nextProps.sort, isAuthRequired)
            }

            else {
                actions.getClientList(nextProps.searchTerm, nextProps.filterStatus, nextProps.filterType, nextProps.page, nextProps.rowsPerPage, nextProps.sort, isAuthRequired)
            }
        }

    },

},
);

export const enhance = compose(
    withStyles(styles),
    withKPI,
    withColumnMenu,
    withSort,
    withSearchTerm,
    withFacetFilter,
    withRoutes,
    withPage,
    withRows,
    loadComponents)
export default enhance(Component)