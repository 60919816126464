import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { compose } from 'recompose';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import TextField from '../../forms/UsedTextField';
import { Field, Form } from 'react-final-form';
import { LOGIN_FIELDS } from '../../utils/constantDefinition';


const styles = (theme) => ({
    card: {
        marginTop: '10%',
        boxSadhow:'none',
        border:'1px solid rgba(0,0,0,0.2)'
    },
    media: {
        height: 140,
    },
    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    typography: {
        color: "#28558a",
        fontWeight: 600,
        fontSize: 20
    }
})

const Component = ({ classes, intl, actions, authenticationError }) => {
    const onSubmit = (values) => {
        if(!values.username||!values.password)
        {
            actions.setErrors(LOGIN_FIELDS)
        }
        else{
            actions.validateLogin(values)

        }
    }

    return (
        <Grid item xs={12}>
            <Grid container justify="center">
                <Grid item xs={4}>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, pristine, form, invalid, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Card className={classes.card}>
                                    <Grid item xs={12}>
                                        <Grid container justify="center" style={{ marginTop: "5%" }}>
                                            <Typography className={classes.typography}>
                                                Authentication
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <CardContent>
                                        <Field
                                            component={TextField}
                                            name="username"
                                            type="text"
                                            label={`${intl.formatMessage({ id: "client.username" })}`}
                                            error={authenticationError ? true : false}
                                            focus={true}

                                        />
                                        <Field
                                            component={TextField}
                                            name="password"
                                            type="password"
                                            label={`${intl.formatMessage({ id: "client.password" })}`}
                                            error={authenticationError ? true : false}

                                        />
                                    </CardContent>
                                    <CardActions>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Grid container justify="center">
                                                    {authenticationError &&
                                                        <Typography variant="body2" style={{ color: "#28558A", textAlign: "center" }}>
                                                            <FormattedHTMLMessage id="authentication.errors" values={{data:authenticationError}} />
                                                        </Typography>
                                                    }
                                                </Grid>
                                                &nbsp;

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justify="center">
                                                    <Button size="large" type="submit" className={classes.button}>
                                                        OK
                                                </Button>

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>
                            </form>
                        )}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export const enhance = compose(
    withStyles(styles),
    injectIntl,
);
export default enhance(Component)