import React from 'react';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Group from '@material-ui/icons/Group'
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom'
import { formatClientDate, formatDateWithFormat } from '../../utils/date';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormErrors from '../../widgets/FormErrors';
import Title from '../../widgets/Title';
import { Spacer } from '../../widgets';
import TextField from '../../forms/UsedTextField';
import Select from '../../forms/Select';
import { country, type, language } from '../../utils/select';
import FormContainer from '../../widgets/FormContainer';
import { parse } from 'date-fns';
import { MANDATORY_FIELDS } from '../../utils/constantDefinition';


const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    addButton: {
        textTransform: 'none',
        backgroundColor: "#4b7cc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid #28558A',

    },
    appBar: {
        marginTop: '2%',
        width: '80%'
    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
})
const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}

const Component = ({ classes, client, clientErrors, intl, actions, isAuthRequired, clientLanguage }) => {
    const onSubmit = (values) => {
        if (!values.code || !values.name || !values.country) {
            actions.setClientErrors(MANDATORY_FIELDS)
        }
        else {
            actions.addClient({
                ...values, contratDate: formatClientDate(parse(
                    values.contratDate,
                    'dd/MM/yyyy',
                    new Date()
                ))
            }, isAuthRequired)


        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ ...client, language: clientLanguage, country: 'france', type: "saas", contratDate: formatDateWithFormat(new Date(), 'dd/MM/yyyy') }}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems="center">
                        <Grid item xs={3}>
                            <Grid container className={classes.header}>
                                <Group className={classes.icon} />
                                <Spacer size="MEDIUM" />
                                <Title text={<FormattedMessage id={"client.add"} />} />
                                <Spacer size="LARGE" />
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container justify="flex-start" className={classes.header}>
                                <Button onClick={() => { form.reset(); actions.setClientErrors() }} className={classes.button} >cancel</Button>
                                <Spacer size="MEDIUM" />
                                <Button type="submit" className={classes.addButton} >save</Button>
                                <Spacer size="LARGE" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {clientErrors && <FormErrors clientErrors={clientErrors} />}&nbsp;
                   </Grid>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Grid item xs={10}>
                                    <AppBar className={classes.appBar} position="static" color="default">
                                        <Tabs
                                            value={0}
                                            indicatorColor="primary"
                                        >
                                            <Tab classes={{ root: classes.tabRoot }} label="details" />

                                        </Tabs>
                                    </AppBar>
                                    <TabContainer>
                                        <FormContainer title={<FormattedMessage id="client.company" />}>

                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={TextField}
                                                        name="code"
                                                        type="text"
                                                        label={`${intl.formatMessage({ id: "client.company.id" })} *`}
                                                        width='90%'
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={TextField}
                                                        name="name"
                                                        type="text"
                                                        label={`${intl.formatMessage({ id: "client.company.name" })} *`}
                                                        width='90%'
                                                    />
                                                </Grid>
                                            </Grid>


                                        </FormContainer>
                                        <FormContainer title={<FormattedMessage id="client.type.type" />}>

                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={TextField}
                                                        name="industry"
                                                        type="text"
                                                        label={intl.formatMessage({ id: "client.type.industry" })}
                                                        width='90%'

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={Select}
                                                        name="type"
                                                        type="select"
                                                        label={intl.formatMessage({ id: "client.type.type" })}
                                                        object={type}
                                                        width='90%'

                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormContainer>
                                        <FormContainer title={<FormattedMessage id="client.location" />}>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={Select}
                                                        name="country"
                                                        type="select"
                                                        label={`${intl.formatMessage({ id: "client.location.country" })} *`}
                                                        object={country}
                                                        withCountry={true}
                                                        width='90%'
                                                        client={client}

                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={Select}
                                                        name="language"
                                                        type="select"
                                                        label={intl.formatMessage({ id: "client.location.language" })}
                                                        object={language}
                                                        width='90%'

                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormContainer>
                                        <FormContainer title={<FormattedMessage id="client.date" />}>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={TextField}
                                                        name="contratDate"
                                                        type="text"
                                                        label={<FormattedMessage id="client.date.openDate" />}
                                                        readOnly={true}
                                                        width='90%'

                                                    />
                                                </Grid>
                                            </Grid>

                                        </FormContainer>
                                        <FormContainer title={<FormattedMessage id="client.note.note" />}>
                                            <Grid item xs={4}>
                                                <Grid container justify="center" alignItems="flex-end">
                                                    <Field
                                                        component={TextField}
                                                        name="note"
                                                        label={intl.formatMessage({ id: "client.note.note" })}
                                                        width='90%'
                                                        multiline
                                                        rowsMax={5}
                                                        spellCheck={false}
                                                    />
                                                </Grid>
                                            </Grid>

                                        </FormContainer>                                        </TabContainer>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            )}
        />
    )
}
const withError = withStateHandlers(() => ({ isError: false }), {
    toggleError: () => value => ({ isError: value }),
});
const loadComponents = lifecycle({
    componentWillMount() {
        const { actions,isAuthRequired } = this.props
        actions.getClientSettings(isAuthRequired)
    },
    componentWillUnmount() {
        const { actions } = this.props
        actions.setClientErrors()
    },
    componentWillReceiveProps(nextProps) {
        const { clientId, history } = this.props

        if (nextProps.clientId !== clientId) {
            history.push(`/client/${nextProps.clientId}`)
        }


    }


},
);
export const enhance = compose(
    withStyles(styles),
    withError,
    withRouter,
    loadComponents,
    injectIntl)
export default enhance(Component)