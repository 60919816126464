import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Content } from '../../widgets';
import { compose, lifecycle } from 'recompose';
import Client from '../../components/Client';
import * as clientActions from '../../actions/clients';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withRoutes from '../../hoc/routes';
import { getClient, getClientUsers, getClientUsersCount, getClientNotes, getSortedAccounts, getClientKpi, getClientErrors, getClientConventions } from '../../selectors/clients';
import { getIsLoading } from '../../selectors/default';
import withLoading from '../../hoc/withLoading';
import * as defaultActions from '../../actions'

const Page = ({ actions, routes, match, client, clientUsers, usersCount, clientNotes, clientConventions, clientOpenAccounts,
    clientKpiStats, clientErrors }) => {
    const route = routes.getRouteByPath(`${match.path}`);
    const isAuthRequired = routes.isAuthRequired(route)

    return (
        <Grid container>
            <Content>
                <Client isAuthRequired={isAuthRequired} actions={actions} client={client}
                    clientUsers={clientUsers} usersCount={usersCount} clientNotes={clientNotes}
                    clientConventions={clientConventions} clientOpenAccounts={clientOpenAccounts}
                    clientKpiStats={clientKpiStats} clientErrors={clientErrors} />
            </Content>
        </Grid>
    )
}
const actions = {
    addClient: clientActions.addClient,
    getClientUsersList: clientActions.getClientUsersList,
    getClientById: clientActions.getClientById,
    getClientConventions: clientActions.getClientConventions,
    setLoadingStatus: defaultActions.setLoadingStatus,
    getClientNotes: clientActions.getClientNotes,
    getClientOpenAccounts: clientActions.getClientOpenAccounts,
    updateClient: clientActions.updateClient,
    updateClientConventions: clientActions.updateClientConventions,
    updateClientNote: clientActions.updateClientNote,
    getClientKpiStatistics: clientActions.getClientKpiStatistics,
    setClientErrors: clientActions.setClientErrors
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    client: getClient,
    clientUsers: getClientUsers,
    isLoading: getIsLoading,
    usersCount: getClientUsersCount,
    clientNotes: getClientNotes,
    clientConventions: getClientConventions,
    clientOpenAccounts: getSortedAccounts,
    clientKpiStats: getClientKpi,
    clientErrors: getClientErrors

})
const loadComponent = lifecycle({
    componentDidMount() {
        const { actions } = this.props
        actions.setLoadingStatus(true);
    },


});
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes,
    loadComponent,
    withLoading,

)
export default enhance(Page)