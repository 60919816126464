import { SET_CLIENT_SETTINGS, SET_EMAIL_SETTINGS, SET_ALERT_SETTINGS } from "../actions/settings";


export default (state = {}, action) => {
    switch (action.type) {
        case SET_CLIENT_SETTINGS:
            return { ...state, clientSettings: action.data };
        case SET_EMAIL_SETTINGS:
            return { ...state, emailSettings: action.data };
        case SET_ALERT_SETTINGS:
            return { ...state, alertSettings: action.data };
        default:
            return state;
    }
};
