import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Slide, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import Select from '../forms/Select';
import { compose } from 'recompose';
import Spacer from './Spacer';
import UsedTextField from '../forms/UsedTextField';
import { sortStatusList } from '../utils/models';

const Transition = props => {
    return <Slide direction="up" {...props} />;
};
const styles = (theme) => ({
    addButton: {
        textTransform: 'none',
        backgroundColor: "#4b7cc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid #28558A',

    },
    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
    root: {
        margin: 0,
        padding: theme.spacing.unit,
        borderBottom: `1px solid ${theme.palette.divider}`,

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing * 1,
        top: theme.spacing * 1,
        color: theme.palette.grey[500],
    },
})
const ConfirmClosing = ({ open, alert, onClose, classes, actions, alertType,page,rowsPerPage,status,sort }) => {

    const onSubmit = (values) => {
        actions.updateAlert(values, alertType,status,page,rowsPerPage,sort, true)
        onClose(false)
    }

    return (

        <Dialog TransitionComponent={Transition}
            open={open}
            fullWidth={true}
        >
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    ...alert,
                }}
                render={({ handleSubmit, pristine, form, invalid, values }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle className={classes.root}>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Grid container justify="flex-start">
                                        <Typography variant="h6" >Edit alert {alert && alert.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justify="flex-end">
                                        <CloseIcon onClick={() => onClose(false)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container justify="center">
                                <Field
                                    component={Select}
                                    name="status"
                                    type="text"
                                    label={`Status`}
                                    width='90%'
                                    object={sortStatusList(alert.status)}


                                />
                            </Grid>
                            <Grid container justify="center">
                                <Field
                                    component={UsedTextField}
                                    name="note"
                                    type="text"
                                    label={`Note`}
                                    width='90%'
                                    multiline
                                    rowsMax={5}
                                    spellCheck={false}


                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.footer}>
                            <Grid container justify="center">
                                <Button disabled={pristine} onClick={() => form.reset()} className={classes.button} >cancel</Button>
                                <Spacer size="MEDIUM" />
                                <Button disabled={pristine} type="submit" className={classes.addButton} >save</Button>
                            </Grid>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>

    )
};


export const enhance = compose(
    withStyles(styles)
)
export default enhance(ConfirmClosing);
