import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Keycloak from '../../../Keycloak'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        width: theme.spacing.unit * 10,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    typography: {
        color: "#28558a",
        fontWeight: 600
    }
});
const Component = ({ classes, actions, history }) => {

    return (
        <AppBar style={{ backgroundColor: "#fff" }} position="static">
            <Toolbar>
                <Grid container alignItems="center">

                    <Grid item xs={10}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Button onClick={() => { Keycloak.logout(); }} className={classes.button}>sign out</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export const enhance = compose(
    withStyles(styles),
    withRouter
);

export default enhance(Component);