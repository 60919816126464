import { combineReducers } from 'redux';
import conventions from './conventions';
import language from './language';
import tutorial from './tutorial';
import authentication from './authentication';
import settings from './settings'
import clients from './clients';
import defaultSettings from './default';
import alerts from './alerts';
import reports from './reports';

const createRootReducer = () =>
  combineReducers({
    conventions,
    language,
    tutorial,
    authentication,
    settings,
    clients,
    defaultSettings,
    alerts,
    reports
  });
export default createRootReducer;
