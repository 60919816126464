import React from 'react';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Error from '@material-ui/icons/Error';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import AllAlerts from './AllAlerts'
import UserD from './UserD';
import UserM from './UserM';
import Family from './Family';
import Conventions from './Conventions';
import Volume from './Volume';
import DialogEdit from '../../widgets/DialogEdit';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        textTransform: 'none',
        fontSize: '14px',
        backgroundColor: '#d9d9d9',
        color: 'black',
        width: 150,
        border: '1px solid #d9d9d9',
        '&:hover': {
            backgroundColor: '#d9d9d9',
        }

    },
    appBar: {
        marginTop: '2%',
        width: 'calc(100% - 50px)',
        marginLeft: 50
    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
})

const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}

const Component = ({ classes, tabValue, toggleSettingsTabs, handleAlert, alert, isDialogOpen, toggleDialog, actions,
    isAuthRequired, alerts, toggleAlertType, alertType, alertsStat, rowsPerPage, page, togglePage, toggleRows, sort,
    toggleSort, toggleFacetFilter, status }) => {
    return (
        <Grid container>


            <Grid item xs={10}>
                <Grid container className={classes.header}>
                    <Grid item xs={3}>
                        <Grid container justify="flex-start" alignItems="flex-end"  >
                            <Error className={classes.icon} />
                            <Spacer size="MEDIUM" />
                            <Title text={<FormattedMessage id="client.alerts" />} />
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Button disabled={true} className={classes.button}>export csv</Button>
                            </Grid >
                        </Grid >
                    </Grid >

                </Grid>
            </Grid>
            <Grid item xs={10}>
                <Grid container justify="center">
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <AppBar className={classes.appBar} position="static" color="default">
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                            >
                                <Tab onClick={() => { toggleSettingsTabs(0); toggleAlertType('') }} classes={{ root: classes.tabRoot }} label="all" />
                                <Tab onClick={() => { toggleSettingsTabs(1); toggleAlertType('USER_D') }} classes={{ root: classes.tabRoot }} label="users D" />
                                <Tab onClick={() => { toggleSettingsTabs(2); toggleAlertType('USER_M') }} classes={{ root: classes.tabRoot }} label="users M" />
                                <Tab onClick={() => { toggleSettingsTabs(3); toggleAlertType('CONVENTION') }} classes={{ root: classes.tabRoot }} label="convention" />
                                <Tab onClick={() => { toggleSettingsTabs(4); toggleAlertType('FAMILY') }} classes={{ root: classes.tabRoot }} label="family" />
                                <Tab onClick={() => { toggleSettingsTabs(5); toggleAlertType('VOLUME') }} classes={{ root: classes.tabRoot }} label="volume" />
                            </Tabs>
                        </AppBar>

                        {tabValue === 0 && (
                            <TabContainer>
                                <AllAlerts isAuthRequired={isAuthRequired} actions={actions} alerts={alerts}
                                    alertType={alertType} alertsStat={alertsStat} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} toggleFacetFilter={toggleFacetFilter} toggleAlertType={toggleAlertType} />
                            </TabContainer>
                        )}
                        {tabValue === 1 && (
                            <TabContainer>
                                <UserD handleAlert={handleAlert} isDialogOpen={isDialogOpen} toggleDialog={toggleDialog}
                                    alerts={alerts} isAuthRequired={isAuthRequired} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} />
                            </TabContainer>
                        )}
                        {tabValue === 2 && (
                            <TabContainer>
                                <UserM handleAlert={handleAlert} isDialogOpen={isDialogOpen} toggleDialog={toggleDialog}
                                    alerts={alerts} isAuthRequired={isAuthRequired} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} />
                            </TabContainer>
                        )}
                        {tabValue === 3 && (
                            <TabContainer>
                                <Conventions handleAlert={handleAlert} isDialogOpen={isDialogOpen} toggleDialog={toggleDialog}
                                    alerts={alerts} isAuthRequired={isAuthRequired} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} />
                            </TabContainer>
                        )}
                        {tabValue === 4 && (
                            <TabContainer>
                                <Family handleAlert={handleAlert} isDialogOpen={isDialogOpen} toggleDialog={toggleDialog}
                                    alerts={alerts} isAuthRequired={isAuthRequired} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} />
                            </TabContainer>

                        )}
                        {tabValue === 5 && (
                            <TabContainer>
                                <Volume handleAlert={handleAlert} isDialogOpen={isDialogOpen} toggleDialog={toggleDialog}
                                    alerts={alerts} isAuthRequired={isAuthRequired} rowsPerPage={rowsPerPage} page={page}
                                    togglePage={togglePage} toggleRows={toggleRows} sort={sort} toggleSort={toggleSort} />
                            </TabContainer>

                        )}

                    </Grid>
                    <DialogEdit alert={alert} open={isDialogOpen} onClose={toggleDialog} actions={actions} alertType={alertType} page={page} rowsPerPage={rowsPerPage} status={status} sort={sort} />
                </Grid>
            </Grid>
        </Grid>
    )
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});
const withAlerts = withStateHandlers(() => ({ alert: undefined }), {
    handleAlert: () => value => ({ alert: value }),
});
const withDialogEdit = withStateHandlers(() => ({ isDialogOpen: false }), {
    toggleDialog: () => value => ({ isDialogOpen: value }),
});
const withAlertType = withStateHandlers(() => ({ alertType: '' }), {
    toggleAlertType: () => value => ({ alertType: value }),
})
const withRows = withStateHandlers(() => ({ rowsPerPage: 10 }), {
    toggleRows: () => event => ({ rowsPerPage: event.target.value }),
});
const withPage = withStateHandlers(() => ({ page: 0 }), {
    togglePage: () => (event, page) => ({ page: page }),
});
const withSort = withStateHandlers(() => ({ sort: [] }), {
    toggleSort: () => value => ({ sort: value }),
});
const withFacetFilter = withStateHandlers(() => ({ status: '' }), {
    toggleFacetFilter: () => (value) => ({ status: value }),
});

const loadComponents = lifecycle({

    componentWillMount() {
        const { isAuthRequired, alertType, page, rowsPerPage, actions, status, sort } = this.props
        actions.getAlertsByType(alertType, status, page, rowsPerPage, sort, isAuthRequired)
    },
    componentWillReceiveProps(nextProps) {
        const { isAuthRequired, alertType, page, rowsPerPage, actions, tabValue, togglePage, toggleSort, status, toggleFacetFilter, sort } = this.props
        if (nextProps.alertType !== alertType || nextProps.page !== page || nextProps.rowsPerPage !== rowsPerPage || nextProps.status !== status || nextProps.sort !== sort) {
            if (nextProps.rowsPerPage * nextProps.alerts.number > nextProps.alerts.totalElements) {
                actions.getAlertsByType(nextProps.alertType, nextProps.status, Math.ceil(nextProps.clientList.totalElements / nextProps.rowsPerPage) - 1, nextProps.rowsPerPage, nextProps.sort, isAuthRequired)
            }

            else { actions.getAlertsByType(nextProps.alertType, nextProps.status, nextProps.page, nextProps.rowsPerPage, nextProps.sort, isAuthRequired) }
        }
        if (nextProps.tabValue !== tabValue) {
            togglePage(undefined, 0);
            toggleSort([]);
            toggleFacetFilter('')

        }
    }



});

export const enhance = compose(
    withStyles(styles),
    withSettingsTabs,
    withAlerts,
    withDialogEdit,
    withRows,
    withPage,
    withAlertType,
    withSort,
    withFacetFilter,
    loadComponents
)
export default enhance(Component)