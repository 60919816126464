import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Settings from '@material-ui/icons/Settings';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Title from '../../widgets/Title';
import { Spacer } from '../../widgets';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import ClientSettings from './ClientSettings';
import EmailSettings from './EmailSettings';
import { emailsList } from '../../utils/select';
import { getEmailContent } from '../../utils/models';
import { Form } from 'react-final-form';
import AlertSettings from './AlertSettings';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    editButton: {
        textTransform: 'none',
        backgroundColor: "#4b7cc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid #28558A',
    },
    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    appBar: {
        marginTop: '2%',
        width: '100%'
    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
})

const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}




const Component = ({ classes, isAuthRequired, toggleSettingsTabs, tabValue,
    toggleEditMode, editMode, toggleTextField, rowsChanges, actions, clientSettings, code, language, handleEmail, emailSettings, alertSettings }) => {

    let Email = emailSettings && getEmailContent(code, language, emailSettings)
    const onSubmit = (values) => {
        if (tabValue === 0) {
            actions.updateClientSettings(rowsChanges, isAuthRequired);
        }
        if (tabValue === 1) {
            actions.updateEmailSettings({
                ...values,
            }, isAuthRequired);

        }
        if (tabValue === 2) {
            actions.updateAlertSettings(rowsChanges, isAuthRequired);
        }
        toggleEditMode(false)
        toggleTextField([])


    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                ...Email,

            }}

            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Grid container className={classes.header}>
                                <Grid item xs={3}>
                                    <Grid container justify="flex-start" alignItems="flex-end"  >
                                        <Settings className={classes.icon} />
                                        <Spacer size="MEDIUM" />
                                        <Title text={<FormattedMessage id="client.settings" />} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            {!editMode ? <Button className={classes.editButton} onClick={() => toggleEditMode(true)} >
                                                edit
                                </Button> :
                                                <Grid container>
                                                    <Button className={classes.button} onClick={() => { toggleEditMode(false); toggleTextField([]) }}  >
                                                        cancel
                                        </Button>
                                                    <Spacer size="MEDIUM" />
                                                    <Button disabled={(tabValue === (0||2) && !rowsChanges[0]) || (tabValue === 1 && (!values.subject || values.template === "<p><br></p>"))} className={classes.editButton} type="submit" >
                                                        save
                                        </Button>
                                                </Grid>}
                                        </Grid >
                                    </Grid >
                                </Grid >

                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justify="center">
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <AppBar className={classes.appBar} position="static" color="default">
                                        <Tabs
                                            value={tabValue}
                                            indicatorColor="primary"
                                        >
                                            <Tab disabled={editMode && tabValue !== 0} onClick={() => toggleSettingsTabs(0)} classes={{ root: classes.tabRoot }} label="client set-up" />
                                            <Tab disabled={editMode && tabValue !== 1} onClick={() => toggleSettingsTabs(1)} classes={{ root: classes.tabRoot }} label="emails" />
                                            <Tab disabled={editMode && tabValue !== 2} onClick={() => toggleSettingsTabs(2)} classes={{ root: classes.tabRoot }} label="alerts" />
                                        </Tabs>
                                    </AppBar>
                                    {tabValue === 0 && (
                                        <TabContainer>
                                            <ClientSettings isAuthRequired={isAuthRequired} rowsChanges={rowsChanges} editMode={editMode}
                                                toggleTextField={toggleTextField} actions={actions} clientSettings={clientSettings} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 1 && (
                                        <TabContainer>
                                            <EmailSettings isAuthRequired={isAuthRequired} editMode={editMode} Email={Email} handleEmail={handleEmail}
                                                actions={actions} emailSettings={emailSettings} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 2 && (
                                        <TabContainer>
                                            <AlertSettings isAuthRequired={isAuthRequired} actions={actions} alertSettings={alertSettings} toggleTextField={toggleTextField} rowsChanges={rowsChanges} editMode={editMode} />
                                        </TabContainer>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            )}
        />
    )
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});
const withEditMode = withStateHandlers(() => ({ editMode: false }), {
    toggleEditMode: () => value => ({ editMode: value }),
});
const withTextField = withStateHandlers(() => ({ rowsChanges: [] }), {
    toggleTextField: () => value => ({ rowsChanges: value }),
});
const withEmail = withStateHandlers(() => ({ language: emailsList[0].language, code: emailsList[0].value }), {
    handleEmail: () => (value1, value2) => ({ code: value1, language: value2 }),
})

export const enhance = compose(
    withStyles(styles),
    withSettingsTabs,
    withTextField,
    withEditMode,
    withEmail)
export default enhance(Component)