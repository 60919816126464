import 'react-app-polyfill/ie9'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';


import { composeWithDevTools } from 'redux-devtools-extension';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import reducers from './reducers';
import './index.css';
import App from './components/App';
import { RoutesContextProvider } from './components/RoutesContext';
import * as serviceWorker from './serviceWorker';
import routes from './routes';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';




let store;
if (process.env.NODE_ENV === "development") {
    store = createStore(
        reducers(),
        composeWithDevTools(
            applyMiddleware(
                thunk,
                createLogger({ collapsed: true }),
            ),
        ),
    );
} else {
    store = createStore(
        reducers(),
        composeWithDevTools(
            applyMiddleware(
                thunk,
            ),
        ),
    );
}
const history = createBrowserHistory();

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInputBase: {
            input: {
                color: '#5c7086',
                fontSize: 15,
                height: 46
            },
            root: {
                height: 46
            },
        },
      
        MuiTableCell: {
            root: {
                padding: '0px 10px 0px 10px'
            }
        },
        
        MuiOutlinedInput:{
            multiline:{
                height:120,
                alignItems:'inherit'
            }
        },
        MuiFormControl: {
            marginNormal: {

                marginTop: 8,
                marginBottom: 4

            }
        }
    },
});

const ROOT = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <RoutesContextProvider value={routes}>
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>

                    <CssBaseline />

                    <Router history={history}>
                        <App />
                    </Router>
                </MuiThemeProvider>
            </Provider>
        </RoutesContextProvider>
    </MuiPickersUtilsProvider>


);
ReactDOM.render(ROOT, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
