import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../../images/logoHIAdatafab.png';
import Menu from './Menu';
import withRoutes from '../../../hoc/routes';
import { loadAuthenticationState } from '../../../utils/sessionStorage';

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};
const Component = ({ classes, routes, isLoggedIn }) => {

    return (
        <AppBar style={{ backgroundColor: "rgba(45,56,130,1)" }} position="static">
            <Toolbar>
                <Grid item xs={10}>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container alignItems="center">

                                <img src={logo} style={{ width: '100px', }} alt="logo HIA" />
                            </Grid>
                        </Grid>

                        { <Grid item xs={10}>
                            <Grid container justify="flex-start" alignItems="center">
                                <Menu />
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export const enhance = compose(
    withStyles(styles),
    withRoutes
);

export default enhance(Component);