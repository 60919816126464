import { format, getMonth, getYear, subMonths } from 'date-fns'


export const formatDate = (date) => {
    return format(new Date(date), 'dd-MM-yyyy')
}
export const formatClientDate = (date) => {
    return format(new Date(date), 'yyyy-MM-dd')
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const formatDateWithTime = (date) =>
    format(new Date(date), 'DD/MM/YYYY HH:mm:ss')

export const formatDateWithFormat = (date, formatDate) => format(new Date(date), formatDate)

export const getDateMonth = (date) => {
    return monthNames[getMonth(new Date(date))]

}
export const getDateYear = (date) => {
    const year = getYear(new Date(date))
    const final = year.toString().substr(2)
    return final

}

export const getSubYear = (date, number) => {
    const lastYear = subMonths(new Date(date), number)
    return formatDateWithFormat(lastYear, 'dd/MM/yyyy')
}

export const formatKpiDate = (date) => {
    const month = getDateMonth(date)
    const year = getDateYear(date)
    const final = month.concat(" '", year)
    return final
}
