import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';

const Transition = props => {
  return <Slide direction="up" {...props} />;
};
const ConfirmNavigation = ({ onCancel, onConfirm }) => (
  <Dialog TransitionComponent={Transition} open={true}>
    <DialogTitle>
      Leaving form
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
       Are you sure to leave this form before saving changes?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Disagree
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
);
ConfirmNavigation.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmNavigation;
