import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl';
import { Field, Form } from 'react-final-form';
import TextField from '../../../../forms/TextField';
import { Spacer } from '../../../../widgets';
import solution from '../../../../images/solution.png'

const styles = theme => ({
    container: {
        //  paddingTop: '40px',
    },
    buttonContainer: {
        marginTop: theme.spacing.unit * 2
    },
    paper: {
        border: '1px solid #A9B2BE',
        backgroundColor: 'rgba(234,234,234,0.5)',
        padding: '12px 20px 15px 20px',
        width: '35%',
        boxShadow: '0 0 0 #fff',
        borderRadius: '1px 1px',
        margin: 0,

        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundColor: '50%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            backgroundColor: '45%',
        },
    },
    typography: {
        color: '#505050',
        fontSize: '12px',
        fontFamily: 'Century Gothic',
        fontWeight: 'bold',
    },
    subTitleContainer: {
        paddingTop: "20px"
    },
    subTitle: {
        color: "#4A6D96",
        fontSize: "12px"
    },
    button: {
        padding: '10px',
        backgroundColor: '#dce6f2',
        color: '#000000',
        border: '1px solid #28558A',
        borderRadius: '5px',
        fontSize: '14px',
        minWidth: 100,
        '&:hover': {
            backgroundColor: '#fff',
            color: '#28558A',
        },
    },
})

const Component = ({ classes, tutorial, handleResponse, response, isSolutionDisplayed, displaySolution }) => {

    const onSubmit = (values) => {
        if (tutorial && tutorial.expected !== values.response) {
            handleResponse(false)
        }
        else {
            handleResponse(true)
        }
    }

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container className={classes.container} >
                        <Grid item xs={12}>
                            <Grid container className={classes.subTitleContainer}  >
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Typography className={classes.subTitle} >
                                            Votre réponse
                            </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Field
                                            component={TextField}
                                            name="response"
                                            type="text"

                                            autoFocus
                                            margin="dense"
                                            variant="outlined"
                                            readOnly={response}
                                            isSolutionDisplayed={isSolutionDisplayed}
                                        />
                                        {<React.Fragment>
                                            {response === false ?
                                                <Close style={{ color: 'red', fontSize: '60px' }} />
                                                :
                                                response === true ?
                                                    <Done style={{ color: 'green', fontSize: '60px' }} />
                                                    :
                                                    isSolutionDisplayed !== true && <Button variant="contained"
                                                        size="large"
                                                        className={classes.button}
                                                        type="submit">
                                                        <FormattedMessage id="ok.label" />
                                                    </Button>}
                                        </React.Fragment>}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container className={classes.buttonContainer} alignItems="baseline">
                                        <Button variant="contained"
                                            size="large"
                                            className={classes.button}
                                            onClick={() => { displaySolution(true); handleResponse() }}>
                                            <FormattedMessage id="challenge.solution" />
                                        </Button>
                                        <Spacer size="LARGE" />
                                        {response === false && <Button variant="contained"
                                            size="large"
                                            className={classes.button}
                                            onClick={() => { form.reset(); handleResponse() }}>
                                            <FormattedMessage id="challenge.new.try" />
                                        </Button>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container className={classes.buttonContainer} alignItems="baseline">
                                        {
                                            isSolutionDisplayed === true &&
                                            <img src={solution} style={{ width: '30%' }} alt="logo HIA" />

                                        }
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </form >
            )}
        />
    )
}
const withResponse = withStateHandlers(() => ({ response: undefined }), {
    handleResponse: () => value => ({ response: value }),
});
const withSolution = withStateHandlers(() => ({ isSolutionDisplayed: false }), {
    displaySolution: () => value => ({ isSolutionDisplayed: value }),
});
export const enhance = compose(
    withStyles(styles),
    withResponse,
    withSolution)
export default enhance(Component);