import React from 'react';
import { compose } from 'ramda'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { getDataSets, getDatasetsLabel } from '../../../utils/models';

const styles = (theme) => ({
    container: {
        marginLeft: theme.spacing.unit * 5
    }
})


const Component = ({ classes, unitValue, accountValue, kpiStatistics }) => {
    const options = {

        scales: {

            xAxes: [{

                gridLines: {
                },
                ticks: {
                    beginAtZero: true,
                    min: 0,

                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    padding: 0,
                    callback: function (value) {
                        if (unitValue === 'failed' || unitValue === 'blocked') {
                            return value + ' %';
                        }
                        return value
                    }
                },
                gridLines: {
                    drawTicks: false,
                    drawBorder: true,
                },

            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontStyle: 'bold',
                fontColor: '#black',
                verticalAlign: 'right',
                fontSize: 14,
                padding: 20

            },

        },
        plugins: {
            datalabels: {
                display: false,
                color: 'black',
            }
        }

    };



    const data = {
        datasets:
            (kpiStatistics && accountValue) === 'level' ? getDataSets(kpiStatistics && kpiStatistics.level, accountValue, unitValue) :
                (kpiStatistics && accountValue) === 'type' ? getDataSets(kpiStatistics && kpiStatistics.type, accountValue, unitValue) :
                    getDataSets(kpiStatistics && kpiStatistics.all, accountValue, unitValue),
        labels: kpiStatistics && getDatasetsLabel(kpiStatistics && kpiStatistics.level, unitValue)
    }

    return (
        <Grid container className={classes.container} >
            <Grid item xs={10}>
                {kpiStatistics && < Bar
                    data={data}
                    options={options}
                />}
            </Grid>
        </Grid>
    )
}


const enhance = compose(
    withStyles(styles),
)
export default enhance(Component)