import { SET_ALERTS, SET_ALERTS_STATISTICS } from "../actions/alerts";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_ALERTS:
            return { ...state, alerts: action.data };
        case SET_ALERTS_STATISTICS:
            return { ...state, alertsStatistics: action.data };
        default:
            return state;
    }
};
