import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
    paper: {
        boxShadow: 'none',
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        border: "inherit",
    },
    typography: {
        color: '#37488C',
        fontSize: 16,
        margin: theme.spacing.unit * 1

    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 8,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        textDecoration: 'none',
        textTransform: 'none',
        borderRadius: '8px',
        backgroundColor: '#5071D4',
        fontWeight: 600,
        padding: '8px 25px 8px 25px',
        color:"#FFF",
        '&:hover': {
            backgroundColor: '#A3B3DF',
            color: 'rgb(93,124,215)',
            boxShadow: '0px -2px 9px 1px rgba(0,0,0, 0.15)'
        },
        '&:active': {
            backgroundColor: '#FFF',
            color: 'rgb(39,57,131)',
            boxShadow: '0px 5px 9px 1px rgba(0,0,0, 0.23)',
            border: 'solid 1px rgb(39,57,131)'
        }
    }

})

const Component = ({ classes,actions,match }) => {
    return (
        <Grid container className={classes.header}>
            <Grid item xs={12} >
                <Grid container justify="center">
                    <Typography className={classes.typography}  >
                        <FormattedHTMLMessage id="mnemonic.reminder1" />
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <Grid container justify="center">
                    <Typography className={classes.typography}  >
                        <FormattedHTMLMessage id="mnemonic.reminder2" />
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" className={classes.header}>
                    <Button className={classes.button} onClick={() => { actions.acknowledgeOfReceipt(match.params.id); }}>
                        <FormattedMessage id="acknowledgment.actionLabel" />
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component)