import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Content } from '../../widgets';
import { compose } from 'recompose';
import AddClient from '../../components/AddClient';
import * as clientActions from '../../actions/clients';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withRoutes from '../../hoc/routes';
import { getClient, getClientId, getClientErrors } from '../../selectors/clients';
import {getClientSettings} from '../../actions/settings'
import { getLanguageSettings } from '../../selectors/settings';

const Page = ({ actions, routes, location, client,clientId,clientErrors,clientLanguage }) => {
    const route = routes.getRouteByPath(location.pathname);
    const isAuthRequired = routes.isAuthRequired(route);
    console.log('clientLanguage',clientLanguage)
    return (
        <Grid container>
            <Content>
                <AddClient isAuthRequired={isAuthRequired} actions={actions} client={client} 
                clientId={clientId} clientErrors={clientErrors} clientLanguage={clientLanguage} />
            </Content>
        </Grid>
    )
}
const actions = {
    addClient: clientActions.addClient,
    setClientErrors:clientActions.setClientErrors,
    getClientSettings:getClientSettings

};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    client: getClient,
    clientId:getClientId,
    clientErrors:getClientErrors,
    clientLanguage:getLanguageSettings
})
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes)
export default enhance(Page)