import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { withStyles, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import TutorialExercice from './TutorialExercice';
import { customizeConventionDisplay } from '../../utils';

const styles = (theme) => ({
    container: {
        paddingTop: '40px',
        paddingLeft: '40px'
    },
    buttonContainer: {
        width: "35%",
        paddingTop: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
    },
    subTitleContainer: {
        paddingTop: "20px"
    },
    subTitle: {
        color: "#4A6D96",
        fontSize: "12px"
    },
    margin: {
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 17,
        color: "#28558A",
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 600,
        color: '#4d4796',
        fontSize: 12,

    },
    paper: {
        border: '1px solid #A9B2BE',
        backgroundColor: 'rgba(234,234,234,0.5)',
        padding: '12px 20px 15px 20px',
        width: '35%',
        boxShadow: '0 0 0 #fff',
        borderRadius: '1px 1px',
        margin: 0,

        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundColor: '50%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            backgroundColor: '45%',
        },
    },
    typography: {
        color: '#505050',
        fontSize: '16px',
        fontFamily: 'Century Gothic',
        fontWeight: 'bold',
    },
    button: {
        marginTop: theme.spacing.unit * 2,
        padding: '10px',
        backgroundColor: '#dce6f2',
        color: '#000000',
        border: '1px solid #28558A',
        borderRadius: '5px',
        fontSize: '14px',
        width: 100,
        '&:hover': {
            backgroundColor: '#fff',
            color: '#28558A',
        },
    },
})

const Component = ({ classes, startExercice, exerciceStarted, tutorial }) => {
    return (
        <Grid container className={classes.container} >
            <Grid item xs={12}>
                <Grid container className={classes.subTitleContainer}  >
                    <Grid item xs={12}>
                        <Grid container >
                            <Typography className={classes.subTitle} >
                                <FormattedMessage id="convention.example" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.paper}  >
                            <Typography className={classes.typography} dangerouslySetInnerHTML={{ __html: tutorial && customizeConventionDisplay(tutorial.convention) }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {!exerciceStarted ? <Grid item xs={12}>
                <Grid container alignItems="center" direction="column" className={classes.buttonContainer}  >
                    <Grid item>
                        <Typography style={{ color: "#28558a", fontSize: '18px', fontWeight: 600 }}>
                            <FormattedMessage id="start.exercice.label" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"
                            size="large"
                            className={classes.button}
                            onClick={() => startExercice(true)}>
                            <FormattedMessage id="ok.label" />
                        </Button>
                    </Grid>
                </Grid>
            </Grid> :
                <TutorialExercice tutorial={tutorial} />}
        </Grid>
    )
}
const withChallenge = withStateHandlers(() => ({ exerciceStarted: false }), {
    startExercice: () => value => ({ exerciceStarted: value }),
});
export const enhance = compose(withStyles(styles), withChallenge);
export default enhance(Component);