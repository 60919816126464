import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Spacer } from '../../widgets';
import { FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

const styles = (theme) => ({
    container: {
        paddingTop: '40px',
        paddingLeft: '40px'
    },
    subTitleContainer: {
        paddingTop: "20px"
    },
    subTitle: {
        color: "#4A6D96",
        fontSize: "12px"
    },
    margin: {
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 17,
        color: "#28558A",
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 600,
        color: '#4d4796',
        fontSize: 12,

    },
})

const Component = ({ classes }) => {
    return (
        <Grid container className={classes.container} >
            <Grid item xs={12}>
                <Grid container className={classes.subTitleContainer}  >
                    <Typography className={classes.subTitle} >
                        <FormattedHTMLMessage id="session.info.firstPart" />
                    </Typography>
                    <Spacer size="SMALL" />
                    <Typography className={classNames(classes.link)}>
                        <FormattedHTMLMessage id="session.hiaSecure" />
                    </Typography>
                    <Typography className={classes.subTitle}>
                        .
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.subTitleContainer}>

                    <Typography className={classes.subTitle}>
                        <FormattedHTMLMessage id="session.info.secondPart" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);