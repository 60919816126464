import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import EditorConvertToHTML from '../../../forms/EditorConvertToHtml';
import { Field } from 'react-final-form'
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
    root: {
        ...theme.mixins.gutters(),
        marginTop: '3%',

    },
    container: {
        marginTop: '2%',
        border: '1px solid black'
    },
    text: {
        marginLeft: '2%',
        height: 350,
        color: '#5c7086'
    }
})

const Component = ({ classes, editMode,clientNotes }) => {
    return (
        <Grid item xs={8}>

            <Grid item xs={12}>
                <Grid container className={classes.root} >
                    {!editMode ?
                        <Typography dangerouslySetInnerHTML={{ __html: clientNotes && clientNotes.note }} />
                        :
                        <Field
                            name="note"
                            component={EditorConvertToHTML}
                            wrapperClassName={classes.container}
                            editorClassName={classes.text}
                        />}
                </Grid>
            </Grid>


        </Grid>
    )
}
const loadComponents = lifecycle({

    componentDidMount() {
        const { actions, isAuthRequired, client } = this.props
        actions.getClientNotes(client.id, isAuthRequired)

    },


},
);
export const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component)