import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AlertsTable from './AlertsTable';
import Facets from './Facets';

const styles = () => ({

})

const Component = ({ sort, anchor, menu, toggleSort, toggleMenu, alerts, alertsStat, toggleFacetFilter,rowsPerPage, 
    page, togglePage, toggleRows,toggleAlertType }) => {
    return (
        <Grid item xs={12}>
            <Grid container style={{ marginLeft: 50 }}>
                <Grid item xs={3}>
                    <Facets alertsStat={alertsStat} toggleFacetFilter={toggleFacetFilter} />
                </Grid>
                <Grid item xs={9} >
                    <AlertsTable sort={sort} toggleSort={toggleSort} toggleMenu={toggleMenu} anchor={anchor} menu={menu} alerts={alerts}
                        rowsPerPage={rowsPerPage} page={page} togglePage={togglePage} toggleRows={toggleRows} toggleAlertType={toggleAlertType} />
                </Grid>
            </Grid>
        </Grid>

    )
}



const withColumnMenu = withStateHandlers(() => ({ anchor: undefined, menu: undefined }), {
    toggleMenu: () => (value1, value2) => ({ anchor: value1, menu: value2 }),
});

export const enhance = compose(
    withStyles(styles),
    withColumnMenu,
)

export default enhance(Component)