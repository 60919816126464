import { path, sortWith, descend, prop, ascend } from 'ramda';
import { createSelector } from 'reselect';


export const getClientList = path(['clients', 'clientList']);
export const getClientsTypeStats = path(['clients', 'clientsTypeStats']);
export const getClient = path(['clients', 'client']);
export const getClientUsers = path(['clients', 'clientUsers']);
export const getClientId = path(['clients', 'clientId']);
export const getClientUsersCount = path(['clients', 'clientUsersCount']);
export const getClientNotes = path(['clients', 'clientNotes']);
export const getClientConventions = path(['clients', 'clientConventions'])
export const getOpenedAccounts = path(['clients', 'clientOpenAccounts']);
export const getSortedAccounts = createSelector(getOpenedAccounts, accounts => accounts && sortWith([
    descend(prop('date'))
])(accounts))
export const getSortedFamily = createSelector(getClientConventions, families => families && sortWith([
    ascend(prop('name')),
])(families))
export const getClientKpi = path(['clients', 'clientKpiStatistics']);
export const getClientErrors = path(['clients', 'clientErrors'])