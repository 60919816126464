import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { withStyles, TableFooter, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ClientUsersCollumnMenu from '../../../../widgets/ClientUsersCollumnMenu';
import { withRouter } from 'react-router-dom'
import { formatDate } from '../../../../utils/date';
import { level, userType } from '../../../../utils/select';
import { getLabel, itSortExist, onSortClick } from '../../../../utils/models';
import TablePaginationActionsWrapped from '../../../../widgets/TablePagination';

const styles = (theme) => ({
    container: {
        marginTop: theme.spacing.unit * 2
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unti * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    typography: {
        color: '#28558a',
    },
    bold: {
        fontWeight: 600
    }
})


const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',

    },

}))(TableCell);
const Component = ({ classes, menu, anchor, toggleMenu, toggleSort, sort, clientUsers, history, page, rowsPerPage,
    togglePage, toggleRows, toggleFilter, searchType, searchLevel }) => {
    return (
        <Grid container className={classes.container} >
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>
                                        <Grid container direction="column">
                                            <Grid item> Name</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('hiaId', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('hiaId', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('hiaId', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Type </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            <ArrowDropDown
                                                onClick={(event) => toggleMenu(event.currentTarget, 'type')}
                                                style={{ color: "black", fontSize: 30 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Level </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            <ArrowDropDown
                                                onClick={(event) => toggleMenu(event.currentTarget, 'level')}
                                                style={{ color: "black", fontSize: 30 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Last activity </Grid>
                               
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>Alert</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    {clientUsers && <TableBody>
                        {clientUsers.content.map(row => (
                            <TableRow key={row.hiaId} onClick={() => history.push(`/client/${row.id}`)}>
                                <TableCell component="th" scope="row">
                                    <Typography className={classNames(classes.typography, classes.bold)}>
                                        {row.hiaId}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: 150 }}>
                                    <Typography className={classNames(classes.typography)}>
                                        {getLabel(row.type, userType)}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: 150 }}>
                                    <Typography className={classNames(classes.typography)}>
                                        {getLabel(row.securityLevel, level)}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography style={{ minWidth: 100 }} className={classNames(classes.typography)}>
                                        {formatDate(row.conventionStartDate)}
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: 250 }}>
                                    <Typography className={classNames(classes.typography)}>
                                        {row.alert}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>}
                    <TableFooter>
                        <TableRow>
                            {clientUsers && <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 50]}
                                colSpan={10}
                                count={clientUsers.totalElements}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={toggleRows}
                                page={page}
                                props={clientUsers}
                                onChangePage={togglePage}
                                ActionsComponent={TablePaginationActionsWrapped}

                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
            <ClientUsersCollumnMenu anchorEl={anchor} toggleMenu={toggleMenu} menu={menu} toggleFilter={toggleFilter}
                searchType={searchType} searchLevel={searchLevel}  />

        </Grid>
    )
}

export const enhance = compose(
    withStyles(styles),
    withRouter, )
export default enhance(Component)