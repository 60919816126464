import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { compose } from 'recompose';

const Styles = () => ({
  container: {
    //height: '100vh',
  },
});

const Container = ({ classes, className, children }) => (
  <Grid container justify="center" className={classNames(className, classes.container)}>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

const enhance = compose(withStyles(Styles));
export default enhance(Container);
