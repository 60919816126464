import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Edit from '@material-ui/icons/Edit';
import { formatDateWithFormat } from '../../../utils/date';
import classNames from 'classnames'
import { getLabel, onSortClick, itSortExist } from '../../../utils/models';
import { level } from '../../../widgets/ClientUsersCollumnMenu';
import TablePaginationActionsWrapped from '../../../widgets/TablePagination';
import CollumnLabel from '../../../widgets/CollumnLabel'
import NoteCollumn from '../../../widgets/NoteCollumn';


const styles = (theme) => ({
    container: {
        marginTop: theme.spacing.unit * 2
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unti * 3,
        overflowX: 'auto',
        boxShadow: 'none'

    },
    table: {
        minWidth: 650,
    },
    typography: {
        color: '#28558a',
    },
    bold: {
        fontWeight: 600
    },
    tableCellBody: {
        color: '#28558a'
    },
    editCollumn: {
        width: 50,
        cursor:'pointer'

    },
    
})

const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: 'inherit',

    },
    body: {
        color: "#28558a"
    }

}))(TableCell);

const Component = ({ classes, sort, toggleSort, handleAlert, toggleDialog, alerts, rowsPerPage, page, togglePage, toggleRows }) => {
    return (
        <Grid container className={classes.container}>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <CustomTableCell>
                                <CollumnLabel label="Alert ID" />
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Convention ID" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('conventionFamily_name', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('conventionFamily_name', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('conventionFamily_name', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <CollumnLabel label="Status" />
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Open Date" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('createdDate', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('createdDate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('createdDate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>

                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Close Date" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('closedDate', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('closedDate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('closedDate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Challenges" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('nbrChallenges', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('nbrChallenges', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('nbrChallenges', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Error Rate" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('errorRate', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('errorRate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('errorRate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell >
                                <CollumnLabel label="Note" />
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alerts && alerts.content.map(row => (
                            <TableRow key={row.id}>
                                <TableCell className={classes.editCollumn}>
                                    <Edit onClick={() => { handleAlert(row); toggleDialog(true) }} />
                                </TableCell>
                                <CustomTableCell >
                                    {row.id}
                                </CustomTableCell>
                                <CustomTableCell width="20%">
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography className={classNames(classes.tableCellBody, classes.bold)} >
                                                {row.conventionFamilyDTO && row.conventionFamilyDTO.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography className={classes.tableCellBody} >
                                                {row.conventionFamilyDTO && getLabel(row.conventionFamilyDTO.level, level)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CustomTableCell>
                                <CustomTableCell  >
                                    {row.status}
                                </CustomTableCell>
                                <CustomTableCell >
                                    {formatDateWithFormat(new Date(row.createdDate), 'dd/MM/yyyy')}
                                </CustomTableCell>
                                <CustomTableCell >
                                    {row.closedDate && formatDateWithFormat(new Date(row.closedDate), 'dd/MM/yyyy')}
                                </CustomTableCell>
                                <CustomTableCell >
                                    {row.nbrChallenges}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {row.errorRate}
                                </CustomTableCell>
                                <CustomTableCell width="30%">
                                <NoteCollumn note={row.note} />
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {alerts && <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                colSpan={10}
                                count={alerts.totalElements}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={toggleRows}
                                page={page}
                                props={alerts}
                                onChangePage={togglePage}
                                ActionsComponent={TablePaginationActionsWrapped}

                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </Grid>
    )
}


const enhance = compose(
    withStyles(styles),
);
export default enhance(Component);