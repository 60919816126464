import React from 'react';
import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { withStyles, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import hiaLogo from '../../images/logoHIAsecure.png'
import { Content } from '../../widgets';
import Tutorial from '../../components/Tutorial';
import * as tutorialActions from '../../actions/tutorial';
import { getTutorialInformation } from '../../selectors/tutorial';
import { Link, withRouter } from 'react-router-dom';
import withRoutes from '../../hoc/routes';

const styles = (theme) => ({
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    title: {
        marginLeft: '60px',
        fontWeight: 600,
        color: '#28558a',
        fontSize: '2.125rem',
        [theme.breakpoints.down('lg')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '1.725rem',
        },


    },

    image: {
        [theme.breakpoints.down('sm')]: {
            width: '50px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '120px',
        },
    },
    button: {
        backgroundColor: '#fff',
        color: 'black',
        fontSize: '16px',
        width: theme.spacing.unit * 10,
        border: '1px solid #28558A',
        textTransform: 'none',

    },
    link: {
        textDecoration: 'underline',
        fontWeight: 500,
        color: '#3058a5',
        fontSize: 15,
        marginTop: theme.spacing.unit * 1,
        '&:hover': {
            backgroundColor: '#fff',
            textDecoration: 'underline',


        },

    },
    

})

const Page = ({ classes, tutorial, routes }) => {

    return (
        <Grid container>
         <Grid container className={classes.header}  >
                <Grid item xs={12} >
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container justify="center" alignItems="center">
                                <img src={ hiaLogo} className={classes.image} alt="logo HIA" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.title}>
                                <FormattedMessage id="tutorial.title" />
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container direction="column" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Button className={classes.button}>
                                        <FormattedMessage id="faq.label" />
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Button component={Link} to={routes.getPathByName('sessionFinished')} className={classes.link} >
                                            <FormattedMessage id="quit.label" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
           
            <Content>
                <Tutorial tutorial={tutorial} />
            </Content>
        </Grid>
    )
}
const actions = {

    getTutorial: tutorialActions.loadTutorialInformation,


};

const mapStateToProps = createStructuredSelector({
    tutorial: getTutorialInformation
});
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const loadComponent = lifecycle({
    componentDidMount() {
        const { actions, match } = this.props
        actions.getTutorial(match.params.id)


    },
    componentWillReceiveProps(nextProps) {
    }

});
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withRoutes,
    loadComponent,
)
export default enhance(Page);