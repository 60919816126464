import React from 'react'
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { Content } from '../../widgets';
import Settings from '../../components/Settings';
import Grid from '@material-ui/core/Grid';
import * as settingsAction from '../../actions/settings';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withRoutes from '../../hoc/routes';
import { getCLientSettings, getEmailSettings,getAlertSettings } from '../../selectors/settings';

const styles = () => ({

})

const Page = ({ actions, routes, location, clientSettings, emailSettings,alertSettings }) => {
    const route = routes.getRouteByPath(location.pathname);
    const isAuthRequired = routes.isAuthRequired(route)
    return (
        <Grid container>
            <Content>
                <Settings actions={actions} isAuthRequired={isAuthRequired} clientSettings={clientSettings} emailSettings={emailSettings} alertSettings={alertSettings} />
            </Content>
        </Grid>
    )
}

const actions = {
    getClientSettings: settingsAction.getClientSettings,
    getEmailSettings: settingsAction.getEmailSettings,
    getAlertSettings: settingsAction.getAlertSettings,
    updateClientSettings: settingsAction.updateClientSettings,
    updateEmailSettings: settingsAction.updateEmailSettings,
    updateAlertSettings:settingsAction.updateAlertSettings

};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    clientSettings: getCLientSettings,
    emailSettings: getEmailSettings,
    alertSettings: getAlertSettings

})
export const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes,
)
export default enhance(Page)