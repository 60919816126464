import React from 'react';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { displayAlertNote } from '../utils/models';

const styles = () => ({
    tableCellBody: {
        color: '#28558a'
    },
})

const Component = ({ classes, note }) => (
    (note && note.length >= 150) ?
        <Tooltip title={note} placement="top-start">
            <Typography className={classes.tableCellBody}>
                {displayAlertNote(note)}
            </Typography>
        </Tooltip> :
         note 
)

export const enhance=withStyles(styles)
export default enhance(Component)