import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { country, type } from '../../../utils/select';
import { getLabel, onSortClick, itSortExist } from '../../../utils/models';
import CollumnMenu from '../../../widgets/CollumnMenu';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActionsWrapped from '../../../widgets/TablePagination';
import { formatDateWithFormat } from '../../../utils/date';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        boxShadow: 'none'
    },
    table: {
        minWidth: 700
    },
});

const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',

    },

}))(TableCell);


const Component = ({ classes, toggleMenu, anchor, menu, toggleSort, sort, clientList, toggleRows, togglePage,
    rowsPerPage, page, toggleFilter, history }) => {

    return (
        <Grid>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Client</CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Type </Grid>
                                   
                                </Grid>

                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Open Accounts </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('nbrOpenAccount', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('nbrOpenAccount', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('nbrOpenAccount', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Open Alerts </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('nbrOpenAlert', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('nbrOpenAlert', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('nbrOpenAlert', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Open Date </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('createDate', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('createDate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('createDate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <Grid item xs={10}>Location </Grid>
                                    <Grid item xs={2}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('country', 'desc', sort) ?
                                                < ExpandLess onClick={() => onSortClick('country', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('country', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientList && clientList.content.map(row => (
                            <TableRow style={{ cursor: 'pointer' }} key={row.id} onClick={() => history.push(`/client/${row.id}`)}>

                                <TableCell width="40%" >
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography style={{ color: '#28558a', fontWeight: 600 }}>
                                                {row.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography>
                                                {row.industry}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell width="10%">
                                    <Typography style={{ color: '#28558a' }}>
                                        {getLabel(row.type, type)}
                                    </Typography>
                                </TableCell>
                                <TableCell width="10%" >
                                    <Typography style={{ color: '#28558a' }}>
                                        {row.nbrOpenAccount}
                                    </Typography>
                                </TableCell>
                                <TableCell width="10%">
                                    <Typography style={{ color: '#28558a' }}>
                                        {row.nbrOpenAlert}
                                    </Typography>
                                </TableCell>
                                <TableCell width="10%">
                                    <Typography style={{ color: '#28558a' }}>
                                        {formatDateWithFormat(row.createDate, 'dd/MM/yyyy')}
                                    </Typography>
                                </TableCell>
                                <TableCell width="20%">
                                    <Typography style={{ color: '#28558a' }}>
                                        {getLabel(row.country, country)}
                                    </Typography>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {clientList && <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                colSpan={10}
                                count={clientList.totalElements}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={toggleRows}
                                page={page}
                                props={clientList}
                                onChangePage={togglePage}
                                ActionsComponent={TablePaginationActionsWrapped}

                            />}
                        </TableRow>
                    </TableFooter>

                </Table>
                <CollumnMenu anchorEl={anchor} toggleMenu={toggleMenu} menu={menu} toggleFilter={toggleFilter} togglePage={togglePage} />
            </Paper>
        </Grid>
    )
}
const loadComponents = lifecycle({
    componentDidMount() {


    },

},
);
export const enhace = compose(
    withStyles(styles),
    withRouter,
    loadComponents)
export default enhace(Component);