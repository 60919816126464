import React from 'react';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles, Button } from '@material-ui/core';
import withRoutes from '../../../hoc/routes';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    paper: {
        boxShadow: 'none',
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        border: "inherit",
    },
    typography: {
        color: '#FFF',
        fontSize: 16,
        margin: theme.spacing.unit * 1

    },
    header: {
        paddingTop: theme.spacing.unit * 2.5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        textDecoration: 'none',
        textTransform: 'none',
        borderRadius: '8px',
        backgroundColor: '#FFF',
        fontWeight: 600,
        padding: '8px 25px 8px 25px',
        color: "rgb(55,72,140)",
        boxShadow: '0px 5px 9px 1px rgba(0,0,0, 0.23)',
        border: 'solid 1px rgb(39,57,131)',

        '&:hover': {
            backgroundColor: 'rgb(84,98,153)',
            color: '#FFF',

        }
    }

})

const Component = ({ classes, actions, history, routes, tutorialToken }) => {
    return (
        <Grid container >
            <Grid item xs={12} >
                <Grid container justify="center" className={classes.header}>
                    <Typography className={classes.typography}  >
                        <FormattedHTMLMessage id="acknowledgment.warning" />
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" >
                   
                    <Button
                                    component={Link}
                                    to={routes.getPathByName('Tutorial',tutorialToken)}
                                    target="_blank"
                                    className={classes.button}>
                                    <FormattedMessage id="tutoriel.label" />
                        </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <Grid container justify="center" className={classes.header}>
                    <Typography className={classes.typography}  >
                        <FormattedMessage id="convention.howto" />
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" >
                    <Button className={classes.button} component={Link}
                        to={routes.getPathByName('sessionFinished')} onClick={() => actions.setAcknowledgeStatus()} >
                        <FormattedMessage id="quit.label" />
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    )
}

export const enhance = compose(
    withStyles(styles),
    withRouter,
    withRoutes, )
export default enhance(Component)