import axios from 'axios';


export const SET_OPEN_ACCOUNTS = 'SET_OPEN_ACCOUNTS';
export const SET_KPI_STATISTICS = 'SET_KPI_STATISTICS';
export const SET_DASHBOARD_STATISTICS = 'SET_DASHBOARD_STATISTICS';

export const setOpenAccounts = (data) => ({ type: SET_OPEN_ACCOUNTS, data });
export const setKpiStatistics = (data) => ({ type: SET_KPI_STATISTICS, data });
export const setDashboardStatistics = (data) => ({ type: SET_DASHBOARD_STATISTICS, data })


export const getOpenAccounts = (datefrom, dateTo, isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/api/v1/statistics/opened-account?dateFrom=${datefrom}&dateTo=${dateTo}`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setOpenAccounts(response.data))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}

export const getKpiStatistics = (dateFrom, dateTo, kpiFilter, isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/api/v1/statistics/kpi?dateFrom=${dateFrom}&dateTo=${dateTo}&kpiType=${kpiFilter}`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    })
        .then((response) => {
            if (response.status === 200) {
                dispatch(setKpiStatistics(response.data))
            }

        }).catch(function (error) {

        })
}

export const getDashboardStatistics = (isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/api/v1/statistics/stat-and-kpi`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired
            }
        }, )
        .then((response) => {
            if (response.status === 200) {
                dispatch(setDashboardStatistics(response.data))
            }

        }).catch(function (error) {

        })
}