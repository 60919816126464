import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getDateMonth, getDateYear, getSubYear,formatDateWithFormat } from '../../../utils/date';

const styles = theme => ({
    root: {
        width: '70%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
        boxShadow: 'none'


    },
    table: {

    },
});



const CustomTableCell = withStyles(() => ({
    head: {
        fontWeight: 600,
        color: 'black',
        fontSize: 13
    },
    body: {
        fontWeight: 600,
        color: 'black',
    },

}))(TableCell);

const Component = ({ classes,clientOpenAccounts }) => {
    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>

                        <CustomTableCell >Total</CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {clientOpenAccounts && clientOpenAccounts.map((row, index) => (
                        <TableRow style={{height:40}} key={index}>
                            <CustomTableCell component="th" scope="row">
                                {getDateMonth(row.date)}{" `"}{getDateYear(row.date)}
                            </CustomTableCell>
                            <TableCell>
                                {row.count}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>)
}
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, client, isAuthRequired } = this.props
        actions.getClientOpenAccounts(client.id,getSubYear(new Date(),11), formatDateWithFormat(new Date(),'dd/MM/yyyy'), isAuthRequired)
    },

},
);
const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component)