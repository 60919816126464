import React from 'react';
import { compose } from 'recompose';
import { withStyles, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
    container: {
        paddingTop: '40px',
       // paddingLeft: '40px'
    },
    subTitleContainer: {
        paddingTop: "20px"
    },
    subTitle: {
        color: "#f30013",
        fontSize: "16px",
        fontWeight: 600
    },
    margin: {
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        }
    },
    title: {
        fontWeight: 600,
        fontSize: 17,
        color: "#28558A",
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 3,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 600,
        color: '#4d4796',
        fontSize: 12,

    },
})

const Component = ({ classes }) => {
    return (
        <Grid container className={classes.container} justfy="center" >

            <Grid item xs={12}>
                <Typography align="center" className={classes.subTitle}>
                    <FormattedMessage id="error.info" />
                </Typography>
                <br /> <br />
                <Divider />
                <br /> <br />
            </Grid>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);