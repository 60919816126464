import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { map, find, propEq } from 'ramda';
import { compose } from 'recompose';
import { withStyles, FormControl, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FlagIcon from '../../widgets/FlagIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  
  formControl: {
    width: "100%",
    "& label span": {
      color: "red"
    }
  },
  notchedOutline: {
    borderColor: "transparent !important"
  },
})
const SelectField = ({ input: { value, name, onChange },
  label, object, meta: { error, touched }, classes, actions, withCountry, width = '100%', readOnly, client, ...rest }) => {
  const takeCodeFromValue = (value) => {
    const element = compose(find(propEq('value', value)))(object)
    if (element) {
      return element.code
    }
  }
  const takeCodeFromLabel = (value) => {
    const element = compose(find(propEq('label', value)))(object)
    if (element) {

      return element.code
    }
  }

  return (
    <FormControl style={{ width: width }} >
      <TextField
        select={readOnly ? false : true}
        label={label}
        {...rest}

        InputProps={{
          readOnly: readOnly,
          classes: {
            notchedOutline: readOnly && classes.notchedOutline
          },
          startAdornment: (
            withCountry && readOnly&& <InputAdornment position="start">
              {value && <FlagIcon code={takeCodeFromValue(value) || takeCodeFromLabel(value)} size={'lg'} />}
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        value={value}
        error={error && touched}
        helperText={touched ? error : undefined}
        margin="normal"
        variant="outlined"
      >
        {map(element => (
          <MenuItem key={element.value} value={element.value}>
            <Grid container>
              {withCountry && <Grid item xs={2}>
                <ListItemIcon>
                  { <FlagIcon code={takeCodeFromValue(element.value)} size={'lg'} />}
                </ListItemIcon>
              </Grid>}
              <Grid item xs={10}>
                <Typography>
                  {element.label}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))(object)}
      </TextField>
    </FormControl>

  )
};


export const enhance = compose(withStyles(styles));
export default enhance(SelectField);
