import { path, find, propEq, compose } from 'ramda';
import { createSelector } from 'reselect';


export const getCLientSettings = path(['settings', 'clientSettings']);
export const getEmailSettings = path(['settings', 'emailSettings']);
export const getAlertSettings = path(['settings', 'alertSettings'])

export const getLanguageSettings = createSelector(getCLientSettings, settings => {
    if(settings){
        const test=compose(find(propEq('code', 'client_language')))(settings)
        if(test){
            return test.value
        }
    }
})