import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, Divider } from '@material-ui/core';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import { customizeConventionDisplay } from '../../utils';
import BeforeAcknowledgment from './BeforeAcknowledgment';
import acknowledgmentIcon from '../../images/image.png';
import AfterAcknowledgment from './AfterAcknowledgment';

const styles = (theme) => ({
    title: {
        fontSize: 25,
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: 20,
        },
        fontFamily: 'Myriad Pro',


    },
    paper: {
        backgroundColor: "#37488C",
        borderRadius: "20px",
        boxShadow: '0 0 0 #fff',
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        textAlign: 'center'



    },
    typography: {
        color: "white",
        fontSize: 18,
        fontWeight: 'bold',
        margin: theme.spacing.unit * 3

    },
    littleTypo: {
        color: '#37488C',
        textAlign: 'left',
        fontFamily: 'Calibri'
    },
    header: {
        paddingBottom: theme.spacing.unit * 0.5,
        paddingTop: theme.spacing.unit * 2,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    mnemonic: {
        fontWeight: 600,
        color: '#37488C',
        fontFamily: 'Myriad Pro',
        fontSize: '18px'


    }

})

const Component = ({ classes, convention, actions, acknowledgeStatus, tutorialToken, match }) => {
    const colorStyle = () => {
        if (acknowledgeStatus) {
            return {
                color: "white",

            }
        }
        else return {
            color: "#37488C",
        }
    }
    return (
        <Grid container justify="center">

            <Grid item xs={12}>
                <Grid container justify="center">
                    <Typography style={colorStyle()} className={classes.title}>
                        <FormattedMessage id="acknowledgment.title" />
                    </Typography>
                </Grid>
            </Grid>
            {acknowledgeStatus &&
                <Fragment>
                    <Grid item xs={12}>
                        <Grid container justify="center" >
                            <img alt='' src={acknowledgmentIcon} style={{ width: '60px', height: '60px' }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center" >
                            <Typography style={{ color: "rgb(40,56, 130)", fontWeight: "bold" }}>
                                <FormattedMessage id="acknowledgment.confirmation" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Fragment>
            }
            <Grid item xs={12}>
                <Grid container justify="center" className={classes.header}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Paper className={classes.paper}>
                            <Typography className={classes.typography} dangerouslySetInnerHTML={{ __html: customizeConventionDisplay(convention.convention) }} />
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container justify="center" className={classes.header}>
                    <Grid item xs={12} sm={10} md={8} lg={5}>
                        <Typography className={classes.littleTypo}>
                            <FormattedMessage id="mnemonic.sample" />
                        </Typography>
                        <Divider />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="center" >
                    <Typography variant="h6" className={classes.mnemonic}>
                        {convention.mnemonique}
                    </Typography>
                </Grid>
            </Grid>

            {!acknowledgeStatus ?
                <BeforeAcknowledgment actions={actions} match={match} /> :
                <AfterAcknowledgment actions={actions} match={match} tutorialToken={tutorialToken} />
            }
        </Grid>
    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component)