import React from 'react';
import { compose, withStateHandlers } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import BarChart from '@material-ui/icons/BarChart';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Spacer from '../../widgets/Spacer';
import Title from '../../widgets/Title';
import Accounts from './Accounts';
import Kpi from './Kpi';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        textTransform: 'none',
        fontSize: '14px',
        backgroundColor: '#d9d9d9',
        color: 'black',
        width: 150,
        border: '1px solid #d9d9d9',
        '&:hover': {
            backgroundColor: '#d9d9d9',
        }

    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
});
const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}

const Component = ({ classes, toggleSettingsTabs, tabValue, isAuthRequired, actions, openAccounts, kpiStatistics }) => {

    return (
        <Grid container>


            <Grid item xs={10}>
                <Grid container className={classes.header}>
                    <Grid item xs={3}>
                        <Grid container justify="flex-start" alignItems="flex-end"  >
                            <BarChart className={classes.icon} />
                            <Spacer size="MEDIUM" />
                            <Title text={<FormattedMessage id="client.reports" />} />
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Button disabled={true} className={classes.button}>export csv</Button>
                            </Grid >
                        </Grid >
                    </Grid >

                </Grid>
            </Grid>
            <Grid item xs={10}>
                <Grid container justify="center">
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <AppBar style={{ marginTop: '2%', width: '100%' }} position="static" color="default">
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                            >
                                <Tab onClick={() => toggleSettingsTabs(0)} classes={{ root: classes.tabRoot }} label="Open accounts" />
                                <Tab onClick={() => toggleSettingsTabs(1)} classes={{ root: classes.tabRoot }} label="KPIs" />
                            </Tabs>
                        </AppBar>
                        {tabValue === 0 && (
                            <TabContainer>
                                <Accounts isAuthRequired={isAuthRequired} actions={actions} openAccounts={openAccounts} />
                            </TabContainer>
                        )}
                        {tabValue === 1 && (
                            <TabContainer>
                                <Kpi kpiStatistics={kpiStatistics} actions={actions} isAuthRequired={isAuthRequired} />
                            </TabContainer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});
export const enhance = compose(
    withStyles(styles),
    withSettingsTabs
)
export default enhance(Component)