import { path, filter, compose, find, prop, reduce, toPairs } from 'ramda';


import SessionFinished from "./pages/SessionFinished";
import ErrorPage from './pages/ErrorPage';
import Tutorial from './pages/Tutorial';
import Authentication from './pages/Authentication';
import Settings from './pages/Settings';
import Home from './pages/Home';
import Client from './pages/Client';
import AddClient from './pages/AddClient';
import Alerts from './pages/Alerts';
import Reports from './pages/Reports';
import Acknowledgment from './pages/Acknowledgment';

const routes = {
 
    sessionFinished: {
        path: "/ackOfreceipt/sessionFinished",
        exact: true,
        component: SessionFinished,
    },
    errorPage: {
        path: "/ackOfReceipt/errorPage",
        exact: true,
        component: ErrorPage,
    },
    Tutorial: {
        path: "/ack/tutorial/:id",
        exact: true,
        component: Tutorial,
    },
    Authentication: {
        path: "/signIn",
        exact: true,
        component: Authentication,
    },
    Settings: {
        path: "/settings",
        exact: true,
        component: Settings,
        auth: true,
    },
    Home: {
        path: "/home",
        exact: true,
        component: Home,
        default: true,
        auth: true,
    },
    Client: {
        path: "/client/:id",
        exact: true,
        component: Client,
        auth: true,
    },
    AddClient: {
        path: "/addClient",
        exact: true,
        component: AddClient,
        auth: true,
    },
    Alerts: {
        path: "/alerts",
        exact: true,
        component: Alerts,
        auth: true,
    },
    Reports: {
        path: "/reports",
        exact: true,
        component: Reports,
        auth: true,
    },
    Acknowledgment:{
        path: "/ack-of-receipt/:language/:id",
        exact: true,
        component: Acknowledgment,
    }
};
const exportedRoutes = compose(
    reduce((acc, [name, r]) => [...acc, { ...r, name }], []),
    toPairs,
    filter(prop('path')),
)(routes);

export const getDefaultRoute = () => find(prop('default'), exportedRoutes);
export const getRouteByName = name => routes[name];
export const getRouteByPath = path => find(r => r.path === path, exportedRoutes);
export const getPathByName = (name, param) => {
    const path = prop('path', getRouteByName(name));
    return param ? `${path.replace(':id', param)}` : path;
};
export const getRouteProp = prop => name => path([name, prop], routes);
export const getRouteRoles = getRouteProp('roles');
export const getRouteAuthProps = name => ({ roles: getRouteRoles(name) });
export const isAuthRequired = route => route && (route.auth || route.roles);

export default {
    getRoutes: () => exportedRoutes,
    getDefaultRoute,
    getRouteByName,
    getPathByName,
    getRouteByPath,
    getRouteProp,
    getRouteRoles,
    getRouteAuthProps,
    isAuthRequired,
};
