import React from 'react';
import { compose } from 'recompose';
import { withStyles, TableFooter } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { formatDateWithFormat } from '../../../../utils/date';
import CollumnMenu from '../../../../widgets/CollumnMenu';
import { getLabel, itSortExist, onSortClick } from '../../../../utils/models';
import { alertType } from '../../../../utils/select';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActionsWrapped from '../../../../widgets/TablePagination';
import CollumnLabel from '../../../../widgets/CollumnLabel';
import NoteCollumn from '../../../../widgets/NoteCollumn';

const styles = (theme) => ({
    container: {
        marginTop: theme.spacing.unit * 2
    },
    root: {
        
        marginTop: theme.spacing.unti * 3,
        overflowX: 'auto',
        boxShadow: 'none',
        width: 'calc(100% - 50px)',

    },
    table: {
        minWidth: 650,
    },
    typography: {
        color: '#28558a',
    },
    bold: {
        fontWeight: 600
    },
})

const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: 'inherit'


    },
    body: {
        color: "#28558a"
    }

}))(TableCell);

const Component = ({ classes, sort, toggleSort, toggleMenu, menu, anchor, alerts, rowsPerPage, page, togglePage, toggleRows, toggleAlertType }) => {


    return (
        <Grid container className={classes.container}>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>
                                <CollumnLabel label="Alert ID" />
                            </CustomTableCell>
                            <CustomTableCell >
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Type" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            <ArrowDropDown
                                                onClick={(event) => toggleMenu(event.currentTarget, 'alertType')}
                                                style={{ color: "black", fontSize: 30 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Open Date" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('createdDate', 'desc', sort) ?
                                                <ExpandLess onClick={() => onSortClick('createdDate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('createdDate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTableCell>
                            <CustomTableCell>
                                <Grid container alignItems="center" >
                                    <CollumnLabel label="Close Date" />
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            {itSortExist('closedDate', 'desc', sort) ?
                                                <ExpandLess onClick={() => onSortClick('closedDate', 'asc', toggleSort)} /> :
                                                <ExpandMore onClick={() => onSortClick('closedDate', 'desc', toggleSort)} />}
                                        </Grid>
                                    </Grid>
                                </Grid></CustomTableCell>
                            <CustomTableCell >
                                <CollumnLabel label="Note" />
                            </CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alerts && alerts.content.map(row => (
                            <TableRow key={row.id}>
                                <CustomTableCell width="10%" >
                                    {row.id}
                                </CustomTableCell>
                                <CustomTableCell width="12%" >
                                    {getLabel(row.type, alertType)}
                                </CustomTableCell >
                                <CustomTableCell width="12%" >
                                    {formatDateWithFormat(new Date(row.createdDate), 'dd/MM/yyyy')}
                                </CustomTableCell>
                                <CustomTableCell width="12%" >
                                    {row.closedDate && formatDateWithFormat(new Date(row.closedDate), 'dd/MM/yyyy')}
                                </CustomTableCell>
                                <CustomTableCell >
                                    <NoteCollumn note={row.note} />
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {alerts && <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                colSpan={10}
                                count={alerts.totalElements}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={toggleRows}
                                page={page}
                                props={alerts}
                                onChangePage={togglePage}
                                ActionsComponent={TablePaginationActionsWrapped}

                            />}
                        </TableRow>
                    </TableFooter>
                </Table>
                <CollumnMenu anchorEl={anchor} toggleMenu={toggleMenu} menu={menu} toggleAlertType={toggleAlertType} />

            </Paper>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component);