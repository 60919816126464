import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { compose, lifecycle } from "recompose";
import Grid from '@material-ui/core/Grid';
import { find, propEq, map } from 'ramda';
import { language } from "../../../utils/select";
import { MenuItem } from "@material-ui/core";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        boxShadow: 'none'
    },
    table: {
        minWidth: 700
    },
});
const CustomTableCell = withStyles(() => ({
    body: {

        color: '#5c7086',
        fontSize: 15

    },

}))(TableCell);

const EditableTableCell = ({ row, fieldName, min, max, onCellValueChange, disabled, rowsChanges }) => {
    const handleTextFieldChange = e => {
        onCellValueChange({
            fieldValue: e.target.value,
            fieldName: fieldName,
            min: min,
            max: max
        });
    };
    const rowExist = rowsChanges && find(propEq('id', row.id))(rowsChanges)
    return (
        <TableCell>
            <Grid container justify="center">
                {row.dataType === 'java.lang.Integer' ? <TextField
                    onChange={handleTextFieldChange}
                    id={fieldName}
                    defaultValue={row[fieldName]}
                    InputProps={{
                        inputProps: {
                            min: (rowExist && rowExist.min) ? rowExist.min : fieldName === 'min' ? 0 : min,
                            max: (rowExist && rowExist.max) ? rowExist.max : max
                        }
                    }}
                    style={{ width: "100px" }}
                    margin="normal"
                    type="number"
                    disabled={disabled}
                /> :
                    <TextField
                        select={row.code === "client_language" && !disabled ? true : false}
                        onChange={handleTextFieldChange}
                        id={fieldName}
                        value={rowExist ? rowExist.value : row[fieldName] === null ? '' : row[fieldName]}
                        style={{ width: "100px" }}
                        margin="normal"
                        disabled={disabled}>
                        {map(element => (
                            <MenuItem key={element.value} value={element.value}>
                                {element.value}
                            </MenuItem>
                        ))(language)}
                    </TextField>}
            </Grid>
        </TableCell>
    );
};

const Component = ({ classes, toggleTextField, rowsChanges, editMode, clientSettings }) => {

    const handleTextFieldChange = (rowIndex, id, change) => {
        const itExist = find(propEq('id', id))(rowsChanges)

        if (!itExist) {
            toggleTextField([
                ...rowsChanges,
                { ...rowsChanges[rowIndex], id: id, [change.fieldName]: change.fieldValue, }
            ])
        }
        else {
            itExist[change.fieldName] = change.fieldValue;
            toggleTextField(rowsChanges)

        }
    }


    return (
        <Paper className={classes.root}>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" >Item </TableCell>
                        <TableCell align="center">Default value</TableCell>
                        <TableCell align="center">Min</TableCell>
                        <TableCell align="center">Max</TableCell>
                        <TableCell align="center">Unit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clientSettings && clientSettings.map((row, index) => {
                        return (
                            <TableRow key={row.id} style={{ height: 70 }}>
                                <CustomTableCell  >{row.libelle}</CustomTableCell>
                                {editMode ? <React.Fragment>
                                    <EditableTableCell

                                        row={row}
                                        fieldName="value"
                                        min={row.min}
                                        max={row.max}
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                        rowsChanges={rowsChanges}
                                    />
                                    <EditableTableCell
                                        row={row}
                                        fieldName="min"
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                        disabled={(row.min || row.max) ? false : true}
                                    />
                                    <EditableTableCell
                                        row={row}
                                        fieldName="max"
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                        disabled={(row.min || row.max) ? false : true}
                                    />
                                </React.Fragment> :
                                    <React.Fragment>
                                        <CustomTableCell align="center"  >{row.value}</CustomTableCell>
                                        <CustomTableCell align="center"  >{row.min}</CustomTableCell>
                                        <CustomTableCell align="center"  >{row.max}</CustomTableCell>
                                    </React.Fragment>}
                                <CustomTableCell align="center"  >{row.unit}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>)
}

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired } = this.props
        actions.getClientSettings(isAuthRequired)

    },

},
);

export const enhance = compose(
    withStyles(styles),
    loadComponents
)
export default enhance(Component)