import React from 'react';
import Grid from '@material-ui/core/Grid';

const Component = ({ label }) => {
    return (
        <Grid item xs={11} >
            <Grid container style={{ marginLeft: '3%' }}>
                {label}
            </Grid>
        </Grid>
    )
}

export default Component;