import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {find,propEq,remove,findIndex} from 'ramda';

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        boxShadow: 'none'
    },
    table: {
        minWidth: 700
    },
});
const CustomTableCell = withStyles(() => ({
    body: {

        color: '#5c7086',
        fontSize: 15

    },

}))(TableCell);

const EditableTableCell = ({ row, fieldName, min, max, onCellValueChange, }) => {
    const handleTextFieldChange = e => {
        onCellValueChange({
            fieldValue: e.target.value,
            fieldName: fieldName,
            min: min,
            max: max,
        });
    };

    return (
        <TableCell >
            <Grid container >

                <TextField
                    onChange={handleTextFieldChange}
                    id={fieldName}
                    defaultValue={row[fieldName]}
                    InputProps={{
                        inputProps: {
                            min: min,
                            max: max
                        }
                    }}
                    style={{ width: "100px" }}
                    margin="normal"
                    type="number"
                />
            </Grid>
        </TableCell>
    );
};
const Component = ({ classes, alertSettings, toggleTextField, rowsChanges,editMode }) => {
    const handleTextFieldChange = (rowIndex, id, change) => {
        const itExist = find(propEq('id', id))(rowsChanges)
        if (!itExist) {
            toggleTextField([
                ...rowsChanges,
                { ...rowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])
        }
        else {
            const index = findIndex(propEq('id', id))(rowsChanges);
            let newRowsChanges = remove(index, 1, rowsChanges)
            toggleTextField([
                ...newRowsChanges,
                { ...newRowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])

        }

    }
    return (
        <Grid>
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell >Value</TableCell>
                            <TableCell >Unit</TableCell>
                            <TableCell >Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alertSettings && alertSettings.map((row,index) => (
                            <TableRow key={row.id} style={{ height: 70 }}>
                                <CustomTableCell>
                                    {row.libelle}
                                </CustomTableCell>
                                {editMode ?
                                    <EditableTableCell

                                        row={row}
                                        fieldName="value"
                                        min={row.min}
                                        max={row.max}
                                        rowsChanges={rowsChanges}
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                    /> :
                                    <CustomTableCell   >{row.value}</CustomTableCell>}
                                <CustomTableCell >{row.unit}</CustomTableCell>
                                <CustomTableCell >{row.description}</CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    )
}
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired } = this.props
        actions.getAlertSettings(isAuthRequired)

    },

},
);
export const enhace = compose(
    withStyles(styles),
    loadComponents)
export default enhace(Component);