import axios from 'axios';

export const SET_CLIENT_SETTINGS = 'SET_CLIENT_SETTINGS';
export const SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS';
export const SET_ALERT_SETTINGS = 'SET_ALERT_SETTINGS'

export const setClientSettings = (data) => ({ type: SET_CLIENT_SETTINGS, data })
export const setEmailSettings = (data) => ({ type: SET_EMAIL_SETTINGS, data })
export const setAlertSettings = (data) => ({ type: SET_ALERT_SETTINGS, data })


export const getClientSettings = (isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/api/v1/settings/default`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    }).then(
        (response) => {
            if (response.status === 200) {
                dispatch(setClientSettings(response.data))
            }
        }).catch(function (error) {
            console.log('error')
        })

}

export const getEmailSettings = (isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/customer-ms/api/v1/tempalte-mail`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    })
        .then((response) => {
            if (response.status === 200) {
                dispatch(setEmailSettings(response.data))
            }

        }).catch(function (error) {

        })
}

export const getAlertSettings = (isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/customer-ms/api/v1/settings/alert`, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    })
        .then((response) => {
            if (response.status === 200) {
                dispatch(setAlertSettings(response.data))
            }

        }).catch(function (error) {

        })
}

export const updateClientSettings = (payload, isAuthRequired) => (dispatch) => {

    axios.put(`/customer-ms/customer-ms/api/v1/settings/default`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired
            },

        }).then((response) => {
            if (response.status === 200) {
                dispatch(getClientSettings(isAuthRequired))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}

export const updateEmailSettings = (payload, isAuthRequired) => (dispatch) => {

    axios.put("/customer-ms/api/v1/template-mail",
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired

            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getEmailSettings(isAuthRequired))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}
export const updateAlertSettings = (payload, isAuthRequired) => (dispatch) => {
    axios.put(`/customer-ms/api/v1/settings/alert`,payload, {
        headers: {
            'Content-Type': 'application/json',
            'auth': isAuthRequired
        },

    })
        .then((response) => {
            if (response.status === 200) {
                dispatch(getAlertSettings(isAuthRequired))
            }

        }).catch(function (error) {

        })
}