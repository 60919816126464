import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    button: {
        marginTop: theme.spacing.unit * 6,
        fontWeight: 600,
        backgroundColor: "#28558A",
        color: '#b3a6a6',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#28558A',
        },

    },
});

export const ButtonWidget = ({ size = "medium", text, classes, alo }) => {
    return (
        <Button variant="contained" size={size}
            className={classes.button}
        >
            <FormattedMessage id={text} />
        </Button>)
};
export const enhance = compose(withStyles(styles))
export default enhance(ButtonWidget);