import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { compose, lifecycle, withStateHandlers } from "recompose";
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { find, findIndex, propEq, remove } from 'ramda';
import { getLabel, itSortExist, onSortClick } from "../../../utils/models";
import { level } from "../../../utils/select";
import { formatDate } from "../../../utils/date";
import ConventionColumnMenu from "../../../widgets/ConventionColumnMenu";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    typography: {
        color: '#5c7086',
        fontSize: 15
    },
    rootCheck: {
        color: red[500],
        '&$checked': {
            color: green[500],
        },
    },
    checked: {},
    headContainer: {
        marginLeft: '3%'
    }

});

const CustomTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#bfbfbf',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: 'inherit'
    },
    body: {

        color: '#5c7086',
        fontSize: 15,

    },

}))(TableCell);


const EditableTableCell = ({ classes, row, fieldName, rowsChanges, onCellValueChange }) => {
    const handleTextFieldChange = e => {
        onCellValueChange({
            fieldValue: e.target.checked,
            fieldName: fieldName,

        });


    };

    let itExist = find(propEq('id', row.id))(rowsChanges)
    return (
        <CustomTableCell width="20%">
            <Checkbox
                checked={itExist ? itExist.active : row[fieldName]}
                defaultValue={row[fieldName]}

                onChange={handleTextFieldChange}
                id={fieldName}
                margin="normal"
                type="checkbox"
                classes={{
                    root: classes.rootCheck,
                    checked: classes.checked,
                }}
            />



        </CustomTableCell>

    );
};
export const EditableTableCellFinal = withStyles(styles)(EditableTableCell);


const Component = ({ classes, toggleTextField, rowsChanges, editMode, clientConventions, toggleMenu, sort, toggleSort, anchor, menu, toggleFilter, searchType, searchLevel }) => {



    const handleTextFieldChange = (rowIndex, id, change) => {
        const itExist = find(propEq('id', id))(rowsChanges)
        if (!itExist) {
            toggleTextField([
                ...rowsChanges,
                { ...rowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])
        }
        else {
            const index = findIndex(propEq('id', id))(rowsChanges);
            let newRowsChanges = remove(index, 1, rowsChanges);
            toggleTextField([
                ...newRowsChanges,
                { ...newRowsChanges[rowIndex], [change.fieldName]: change.fieldValue, id: id }
            ])
        }
    }

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <CustomTableCell  >
                            <Grid container alignItems="center"  >
                                <Grid item xs={11}>
                                    <Grid container className={classes.headContainer}>
                                        Family
                                </Grid>
                                </Grid>
                                {!editMode && <Grid item xs={1}>
                                    <Grid container justify="flex-end">

                                        {itSortExist('conventionFamily_name', 'desc', sort) ?
                                            < ExpandLess onClick={() => onSortClick('conventionFamily_name', 'asc', toggleSort)} /> :
                                            <ExpandMore onClick={() => onSortClick('conventionFamily_name', 'desc', toggleSort)} />}
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </CustomTableCell>


                        <CustomTableCell  >
                            <Grid container alignItems="center" >
                                <Grid item xs={11}>
                                    <Grid container className={classes.headContainer}>
                                        Level
                                    </Grid>
                                </Grid>
                                {!editMode &&
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            <ArrowDropDown onClick={(event) => toggleMenu(event.currentTarget, 'level')} style={{ color: "black" }} />
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell  >
                            <Grid container alignItems="center"  >
                                <Grid item xs={11}>
                                    <Grid container className={classes.headContainer}>
                                        Status
                                        </Grid>
                                </Grid>
                                {!editMode &&
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            <ArrowDropDown onClick={(event) => toggleMenu(event.currentTarget, 'status')} style={{ color: "black" }} />
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </CustomTableCell>
                        <CustomTableCell  >
                            <Grid container alignItems="center"  >
                                <Grid item xs={11}>
                                    <Grid container className={classes.headContainer}>
                                        Import on
                                </Grid>
                                </Grid>
                                {!editMode && <Grid item xs={1}>
                                    <Grid container justify="flex-end">
                                        {itSortExist('createdDate', 'desc', sort) ?
                                            < ExpandLess onClick={() => onSortClick('createdDate', 'asc', toggleSort)} /> :
                                            <ExpandMore onClick={() => onSortClick('createdDate', 'desc', toggleSort)} />}

                                    </Grid>
                                </Grid>}
                            </Grid>
                        </CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clientConventions && clientConventions.map((row, index) => {
                        return (
                            <TableRow key={row.id}>
                                <CustomTableCell width="30%" >                                 
                                       {row.name}
                                </CustomTableCell>
                                <CustomTableCell width="20%" >{getLabel(row.level, level)}</CustomTableCell>
                                {editMode ?
                                    <EditableTableCellFinal
                                        row={row}
                                        rowsChanges={rowsChanges}
                                        fieldName="active"
                                        onCellValueChange={(event) => handleTextFieldChange(index, row.id, event)}
                                    /> :
                                    <CustomTableCell width="20%">{row.active ? 'On' : 'Off'}</CustomTableCell>}
                                <CustomTableCell width="30%" >{formatDate(row.createdDate)}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <ConventionColumnMenu anchorEl={anchor} toggleMenu={toggleMenu} menu={menu} toggleFilter={toggleFilter} searchType={searchType}
                searchLevel={searchLevel} />

        </Paper>
    );
}


const withSort = withStateHandlers(() => ({ sort: [] }), {
    toggleSort: () => value => ({ sort: value }),
});
const withColumnMenu = withStateHandlers(() => ({ anchor: undefined, menu: undefined }), {
    toggleMenu: () => (value1, value2) => ({ anchor: value1, menu: value2 }),
});

const withFilter = withStateHandlers(() => ({ searchType: '', searchLevel: '' }), {
    toggleFilter: () => (value1, value2) => ({ searchLevel: value1, searchType: value2 }),
});
const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired, client, sort, searchLevel, searchType } = this.props
        actions.getClientConventions(client.id, searchLevel, searchType, sort, isAuthRequired)

    },
    componentWillReceiveProps(nextProps) {
        const { actions, isAuthRequired, sort, searchLevel, searchType } = this.props
        if (nextProps.sort !== sort || nextProps.searchLevel !== searchLevel || nextProps.searchType !== searchType) {
            actions.getClientConventions(nextProps.client.id, nextProps.searchLevel, nextProps.searchType, nextProps.sort,isAuthRequired)
        }
    },

},
);
export const enhance = compose(
    withStyles(styles),
    withSort,
    withColumnMenu,
    withFilter,
    loadComponents

)
export default enhance(Component);
