import React, {useEffect, useState} from 'react';


import { createStructuredSelector } from 'reselect';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { getIsLoggedInStatus } from '../../selectors/authentication';
import Authentication from '../../pages/Authentication';
import withRoutes from '../../hoc/routes';
import { loadAuthenticationState } from '../../utils/sessionStorage';
import keycloak from '../../Keycloak'
import { setToken } from '../../actions/authentication';
const Auth = ({ isLoggedIn,children }) => {



  
 // if (!isLoggedIn && loadAuthenticationState() === null ) return <Authentication />;

// while(!keycloak.token === null && !keycloak.token === undefined) {
//   return children
// }
console.log(keycloak.clientId)
console.log(children);

 return children;
};



const mapStateToProps = createStructuredSelector({
  isLoggedIn: getIsLoggedInStatus
});

const enhance = compose(
  connect(mapStateToProps),
  withRoutes,
);

export default enhance(Auth);
