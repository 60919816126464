import React, { Fragment } from 'react';
import Title from '../../widgets/Title';
import { Spacer } from '../../widgets';
import Group from '@material-ui/icons/Group'
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import EditClient from './EditClient';
import { formatClientDate, formatDateWithFormat } from '../../utils/date';
import FormErrors from '../../widgets/FormErrors';
import { getLabel } from '../../utils/models';
import { language, country, type } from '../../utils/select';
import { withRouter } from 'react-router-dom';
import ConfirmClosing from '../../widgets/ConfirmClosing';
import ClientUsers from './ClientUsers';
import Accounts from './Accounts';
import ConventionSettings from './ConventionSettings';
import Kpi from './Kpi';
import Notes from './Notes';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { parse } from 'date-fns';
import { MANDATORY_FIELDS } from '../../utils/constantDefinition';

const styles = (theme) => ({
    icon: {
        fontSize: 40,
        marginLeft: "2%",
        color: 'rgba(45,56,130,1)',
    },
    header: {
        paddingBottom: theme.spacing.unit * 1,
        paddingTop: theme.spacing.unit * 5,
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 2.5,

    },
    button: {
        backgroundColor: '#fff',
        color: '#777777',
        fontSize: '14px',
        width: 150,
        border: '1px solid rgba(45,56,130,1)',
        textTransform: 'none',

    },
    addButton: {
        textTransform: 'none',
        backgroundColor: "#4b7cc682",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',

    },
    closeButton: {
        textTransform: 'none',
        backgroundColor: "#ccc1da",
        color: "#777777",
        fontSize: '14px',
        width: 150,
        '&:hover': {
            backgroundColor: '#fff',
        },
        border: '1px solid rgba(45,56,130,1)',

    },
    appBar: {
        marginTop: '2%',
        width: '100%'
    },
    tabRoot: {
        textTransform: 'initial',
        fontSize: '0.8125rem',
    },
    secondButton: {

        textTransform: 'none',
        fontSize: '14px',
        backgroundColor: '#d9d9d9',
        color: 'black',
        width: 150,
        border: '1px solid #d9d9d9',
        '&:hover': {
            backgroundColor: '#d9d9d9',
        }

    },
})

const TabContainer = ({ children }) => {
    return <Typography component="div">{children}</Typography>;
}


const Component = ({ classes, toggleSettingsTabs, tabValue, client, isAuthRequired, actions, isError, toggleError,
    editMode, toggleEditMode, toggleDialog, dialogOpened, clientUsers, usersCount, toggleTextField, rowsChanges,
    clientNotes, clientConventions, clientOpenAccounts, clientKpiStats, clientErrors }) => {

    const onSubmit = (values) => {
        if (tabValue === 0) {
            if (!values.code || !values.name || !values.country) {
                actions.setClientErrors(MANDATORY_FIELDS)
            }
            else {
                actions.updateClient({
                    ...values, contratDate: formatClientDate(
                        parse(
                            values.contratDate,
                            'dd/MM/yyyy',
                            new Date()
                        ))
                }, isAuthRequired)
            }
        }
        if (tabValue === 5) {
            actions.updateClientNote({
                ...values, note: convertToRaw(values.note.getCurrentContent()).blocks[0].text &&
                    draftToHtml(convertToRaw(values.note.getCurrentContent()))
            }, client.id, isAuthRequired)
            toggleEditMode(false)
        }
        if (tabValue === 4) {
            actions.updateClientConventions(rowsChanges, client.id, isAuthRequired)
            toggleEditMode(false)

        }
    }

    const displayButton = (tabValue, editMode, form) => {
        if (tabValue === 0 || tabValue === 4 || tabValue === 5) {
            if (editMode) {
                return (

                    <Fragment>
                        <Grid item xs={8}>
                            <Grid container justify="flex-start" alignItems="flex-end">
                                <Button onClick={() => {
                                    toggleEditMode(false);
                                    (tabValue === 0 || tabValue === 5) &&
                                        form.reset();
                                    actions.setClientErrors()
                                }}
                                    className={classes.button} >
                                    cancel
                                    </Button>
                                <Spacer size="MEDIUM" />
                                <Button type="submit" className={classes.addButton} >save</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container justify="flex-end">
                                {tabValue === 0 && <Button disabled onClick={() => toggleDialog(true)} className={classes.closeButton} >
                                    close account
                                            </Button>}
                            </Grid>
                        </Grid>
                        <FormErrors clientErrors={clientErrors}/> 
                    </Fragment>



                )
            }
            else {
                return (
                    <Grid item xs={4}>
                        <Grid container justify="flex-start" alignItems="flex-end">
                            <Button onClick={() => { toggleEditMode(true); toggleTextField([]) }} className={classes.addButton} >edit</Button>
                        </Grid>
                    </Grid>)
            }

        }
        else {
            return (
                <Grid item xs={4}>
                    <Grid container justify="flex-start" alignItems="flex-end">
                        <Button disabled={true} className={classes.secondButton}>export csv</Button>
                    </Grid>
                </Grid>
            )
        }

    }
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={tabValue === 0 ? {
                ...client,
                language: editMode ? client.language : client && getLabel(client.language, language),
                country: editMode ? client.country : client && getLabel(client.country, country),
                type: editMode ? client.type : client && getLabel(client.type, type),
                industry: !editMode ? ' ' : client && client.industry,
                contratDate: client && formatDateWithFormat(new Date(client.contratDate), 'dd/MM/yyyy')
            } : {
                    ...clientNotes,
                    note: clientNotes && clientNotes.note && EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(clientNotes.note)))
                }}
            render={({ handleSubmit, pristine, form, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems="center">

                        <Grid item xs={10}>
                            <Grid container className={classes.header}>
                                <Grid item xs={3}>
                                    <Grid container justify="flex-start" alignItems="flex-end"  >
                                        <Group className={classes.icon} />
                                        <Spacer size="MEDIUM" />
                                        <Title text={client && client.name} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        {displayButton(tabValue, editMode, form)}
                                    </Grid >
                                </Grid >

                            </Grid>
                        </Grid>
                        <FormErrors clientErrors={clientErrors} />

                        <Grid item xs={10}>
                            <Grid container justify="center">
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <AppBar className={classes.appBar} position="static" color="default">
                                        <Tabs
                                            value={tabValue}
                                            indicatorColor="primary"
                                        >
                                            <Tab disabled={tabValue !== 0 && editMode} onClick={() => toggleSettingsTabs(0)} classes={{ root: classes.tabRoot }} label="details" />
                                            <Tab disabled={tabValue !== 1 && editMode} onClick={() => toggleSettingsTabs(1)} classes={{ root: classes.tabRoot }} label="users" />
                                            <Tab disabled={tabValue !== 2 && editMode} onClick={() => toggleSettingsTabs(2)} classes={{ root: classes.tabRoot }} label="open accounts" />
                                            <Tab disabled={tabValue !== 3 && editMode} onClick={() => toggleSettingsTabs(3)} classes={{ root: classes.tabRoot }} label="KPIs" />
                                            <Tab disabled={tabValue !== 4 && editMode} onClick={() => toggleSettingsTabs(4)} classes={{ root: classes.tabRoot }} label="conventions" />
                                            <Tab disabled={tabValue !== 5 && editMode} onClick={() => toggleSettingsTabs(5)} classes={{ root: classes.tabRoot }} label="notes" />
                                        </Tabs>
                                    </AppBar>
                                    {tabValue === 0 && (
                                        <TabContainer>
                                            <EditClient editMode={editMode} client={client} values={values} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 1 && (
                                        <TabContainer>
                                            <ClientUsers actions={actions} client={client} isAuthRequired={isAuthRequired} clientUsers={clientUsers} usersCount={usersCount} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 2 && (
                                        <TabContainer>
                                            <Accounts actions={actions} isAuthRequired={isAuthRequired} client={client} clientOpenAccounts={clientOpenAccounts} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 3 && (
                                        <TabContainer>
                                            <Kpi clientKpiStats={clientKpiStats} actions={actions} client={client} isAuthRequired={isAuthRequired} />
                                        </TabContainer>
                                    )}
                                    {tabValue === 4 && (
                                        <TabContainer>
                                            <ConventionSettings editMode={editMode} actions={actions} client={client} isAuthRequired={isAuthRequired} toggleTextField={toggleTextField} rowsChanges={rowsChanges} clientConventions={clientConventions} />
                                        </TabContainer>

                                    )}
                                    {tabValue === 5 && (
                                        <TabContainer>
                                            <Notes editMode={editMode} actions={actions} client={client} isAuthRequired={isAuthRequired} clientNotes={clientNotes} />
                                        </TabContainer>

                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ConfirmClosing open={dialogOpened} onClose={toggleDialog} toggleEditMode={toggleEditMode} />
                </form>
            )}
        />
    )
}
const withSettingsTabs = withStateHandlers(() => ({ tabValue: 0 }), {
    toggleSettingsTabs: () => value => ({ tabValue: value }),
});
const withError = withStateHandlers(() => ({ isError: false }), {
    toggleError: () => value => ({ isError: value }),
});
const withEditMode = withStateHandlers(() => ({ editMode: false }), {
    toggleEditMode: () => value => ({ editMode: value }),
});
const withDialog = withStateHandlers(() => ({ dialogOpened: false }), {
    toggleDialog: () => value => ({ dialogOpened: value })
});

const withTextField = withStateHandlers(() => ({ rowsChanges: [] }), {
    toggleTextField: () => value => ({ rowsChanges: value }),
});

const loadComponents = lifecycle({

    componentWillMount() {
        const { isAuthRequired, match, actions } = this.props
        actions.getClientById(match.params.id, isAuthRequired)
    },

    componentWillReceiveProps(nextProps) {
        const { client, toggleEditMode } = this.props
        if (nextProps.client !== client) {
            toggleEditMode(false)
        }
    },
    componentWillUnmount() {
        const { actions } = this.props
        actions.setClientErrors()
    }

});
export const enhance = compose(
    withStyles(styles),
    withSettingsTabs,
    withEditMode,
    withError,
    withDialog,
    withTextField,
    withRouter,
    loadComponents)
export default enhance(Component);