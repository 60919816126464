import axios from 'axios';

export const SET_CONVENTION_BY_HASH = 'SET_CONVENTION_BY_HASH';
export const SET_ACKNOWLEDGE_STATUS = 'SET_ACKNOWLEDGE_STATUS';
export const SET_URL_ERROR = 'SET_URL_ERROR';

export const setConventionByHash = ({ data }) => ({ type: SET_CONVENTION_BY_HASH, payload: data })
export const setAcknowledgeStatus = (status) => ({ type: SET_ACKNOWLEDGE_STATUS, status });
export const setUrlError = (error) => ({ type: SET_URL_ERROR, error })


export const loadConventionByHash = (id, language) => (dispatch) => {

    axios.post(`/customer-ms/api/v1/conventions/${language}/ack-start/?ackToken=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then((response) => {
            if (response.status === 200) {
                dispatch(setConventionByHash(response));
            }
        }).catch(function (error) {
            dispatch(setUrlError(error.response.data.errors[0].key));
        })
}
export const acknowledgeOfReceipt = (id) => (dispatch) => {

    axios.put(`/customer-ms/api/v1/conventions/ack?ackToken=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*'
            },

        }).then(() => {
                dispatch(setAcknowledgeStatus(true));
            
        }).catch(function (error) {
            // dispatch(setLoginError(error.response.data));
        })
}


