import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Content } from '../../widgets';
import withRoutes from '../../hoc/routes';
import Alerts from '../../components/Alerts';
import * as alertsActions from '../../actions/alerts';
import { getAlerts, getAlertsStat } from '../../selectors/alerts';


const Page = ({ routes, match, actions, alerts,alertsStat }) => {
    const route = routes.getRouteByPath(`${match.path}`);
    const isAuthRequired = routes.isAuthRequired(route)
    return (
        <Grid container>
            <Content>
                <Alerts actions={actions} isAuthRequired={isAuthRequired} alerts={alerts} alertsStat={alertsStat} />
            </Content>
        </Grid>
    )
}
const actions = {
    getAlertsByType: alertsActions.getAlertsByType,
    updateAlert:alertsActions.updateAlert,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
    alerts: getAlerts,
    alertsStat:getAlertsStat

})
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes)
export default enhance(Page)