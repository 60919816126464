import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import TutorialResponse from './TutorialResponse';

const styles = theme => ({
    container: {
        paddingTop: '40px',
    },
    paper: {
        border: '1px solid #A9B2BE',
        backgroundColor: 'rgba(234,234,234,0.5)',
        padding: '12px 20px 15px 20px',
        width: '35%',
        boxShadow: '0 0 0 #fff',
        borderRadius: '1px 1px',
        margin: 0,

        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundColor: '50%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            backgroundColor: '45%',
        },
    },
    typography: {
        color: '#505050',
        fontSize: '16px',
        fontFamily: 'Century Gothic',
        fontWeight: 'bold',
    },
    subTitleContainer: {
        paddingTop: "20px"
    },
    subTitle: {
        color: "#4A6D96",
        fontSize: "12px"
    },
})

const Component = ({ classes, tutorial }) => {

    return (
        <Grid container className={classes.container} >
            <Grid item xs={12}>
                <Grid container className={classes.subTitleContainer}  >
                    <Grid item xs={12}>
                        <Grid container >
                            <Typography className={classes.subTitle} >
                                <FormattedMessage id="tutorial.challenge" />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className={classes.paper}  >
                            <Typography className={classes.typography}>
                                {tutorial && tutorial.challenge}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TutorialResponse tutorial={tutorial} />
        </Grid>
    )
}

export const enhance = compose(withStyles(styles))
export default enhance(Component);