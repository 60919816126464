import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { compose } from 'recompose';

const styles = () => ({
    root: {
       marginTop: '1%',
    },

    typography: {
        fontWeight: 600,
        color: '#4A6D96',
        fontSize:16
    }
})
const Component = ({ children, title, classes }) => {

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container alignItems="center" >
                    <Grid item xs={1}>
                        <Grid container justify="center" alignItems="center"  >
                            <Typography  className={classes.typography}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export const enhance = compose(withStyles(styles));
export default enhance(Component);