import axios from 'axios';

export const SET_ALERTS = 'SET_ALERTS';
export const SET_ALERTS_STATISTICS = "SET_ALERTS_STATISTICS";

export const setAlerts = (data) => ({ type: SET_ALERTS, data })
export const setAlertsStatistics = data => ({ type: SET_ALERTS_STATISTICS, data })

export const getAlertsByType = (alertType,status,page,size,sort, isAuthRequired) => (dispatch) => {
    axios.get(`/customer-ms/api/v1/alerts?type=${alertType}&status=${status}&page=${page}&size=${size}${sort.map(element => (`&sort=${element.type},${element.direction}`)).join('')}`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setAlerts(response.data))
            dispatch(getAlertStatiscs(isAuthRequired))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}
export const updateAlert = (payload, alertType,status,page,rowsPerPage,sort, isAuthRequired) => (dispatch) => {

    axios.put("/customer-ms/api/v1/alerts",
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                'Acces-Control-Allow-Origin': '*',
                'auth': isAuthRequired

            },

        }).then((response) => {
            if (response.status === 200) {

                dispatch(getAlertsByType(alertType,status,page,rowsPerPage,sort, isAuthRequired))
            }
        }).catch(function (error) {
            console.log('ko')
        })
}

export const getAlertStatiscs = (isAuthRequired) => dispatch => {
    axios.get(`/customer-ms/api/v1/alerts/stat-by-status`, {

        headers: {
            'Content-Type': 'application/json',
            'Acces-Control-Allow-Origin': '*',
            'auth': isAuthRequired
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setAlertsStatistics(response.data))
        }
    }).catch(function (error) {
        console.log('koAdd')
    })
}