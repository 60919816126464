import { SET_LOADING_STATUS } from "../actions";


export default (state = {}, action) => {
    switch (action.type) {
        case SET_LOADING_STATUS:
            return { ...state, isLoading: action.status };
       
        default:
            return state;
    }
};
