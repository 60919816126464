import { SET_CLIENT_LIST, SET_CLIENTS_TYPE_STATS, SET_CLIENT, SET_CLIENT_USERS, SET_CLIENT_ID, SET_CLIENT_CONVENTIONS, SET_CLIENT_USERS_COUNT, SET_CLIENT_NOTES, SET_ClIENT_OPEN_ACCOUNTS, SET_CLIENT_KPI_STATISTICS, SET_CLIENT_ERROS } from "../actions/clients";
import { LOGOUT } from "../actions/authentication";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_CLIENT_LIST:
            return { ...state, clientList: action.data };
        case SET_CLIENTS_TYPE_STATS:
            return { ...state, clientsTypeStats: action.data };
        case SET_CLIENT: {
            return { ...state, client: action.data };
        }
        case SET_CLIENT_ID: {
            return { ...state, clientId: action.id }
        }
        case SET_CLIENT_CONVENTIONS: {
            return { ...state, clientConventions: action.data }
        }
        case SET_CLIENT_USERS: {
            return { ...state, clientUsers: action.data }
        }
        case SET_CLIENT_USERS_COUNT: {
            return { ...state, clientUsersCount: action.data }
        }
        case SET_CLIENT_NOTES: {
            return {
                ...state, clientNotes: action.data
            }

        }
        case SET_ClIENT_OPEN_ACCOUNTS: {
            return { ...state, clientOpenAccounts: action.data }
        }
        case SET_CLIENT_KPI_STATISTICS: {
            return { ...state, clientKpiStatistics: action.data }
        }
        case LOGOUT: {
            return { ...state, clientList: undefined }
        }
        case SET_CLIENT_ERROS: {
            return { ...state, clientErrors: action.data }
        }
        default:
            return state;
    }
};
