import { SET_TUTORIAL_INFORMATION, SET_TUTORIAL_FAQ_URLS } from "../actions/tutorial";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_TUTORIAL_INFORMATION:
            return { ...state, tutorialInformation: action.data };
            case SET_TUTORIAL_FAQ_URLS:
            return { ...state, urls: action.data };

        default:
            return state;
    }
};
