import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Filters from './Filters';
import Charts from './Charts';
import { getSubYear,formatDateWithFormat } from '../../../utils/date';

const styles = (theme) => ({
    container: {
        marginTop: theme.spacing.unit * 3
    }
})



const Component = ({ toggleUnitFilter, toggleAccountFilter, unitValue, accountValue, classes, clientKpiStats }) => {

    return (
        <Grid container className={classes.container}>
            <Grid item xs={3}>
                <Filters toggleUnitFilter={toggleUnitFilter} toggleAccountFilter={toggleAccountFilter} unitValue={unitValue} accountValue={accountValue} />
            </Grid>
            <Grid item xs={9}>
                <Charts unitValue={unitValue} accountValue={accountValue} kpiStatistics={clientKpiStats} />
            </Grid>
        </Grid>

    )
}


const withAccountsFilter = withStateHandlers(() => ({ accountValue: 'all' }), {
    toggleAccountFilter: () => (value) => ({ accountValue: value }),
})
const withUnitFilter = withStateHandlers(() => ({ unitValue: 'failed' }), {
    toggleUnitFilter: () => (value) => ({ unitValue: value }),
})

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired, unitValue,client } = this.props
        actions.getClientKpiStatistics(client.id, getSubYear(new Date(), 5), formatDateWithFormat(new Date(), 'dd/MM/yyyy'), unitValue, isAuthRequired)
        
    },
    componentWillReceiveProps(nextProps) {
        const { actions, isAuthRequired, unitValue,client } = this.props

        if (nextProps.unitValue !== unitValue) {
            actions.getClientKpiStatistics(client.id, getSubYear(new Date(), 5), formatDateWithFormat(new Date(), 'dd/MM/yyyy'), nextProps.unitValue, isAuthRequired)

        }

    },

},
);
export const enhance = compose(
    withStyles(styles),
    withAccountsFilter,
    withUnitFilter,
    loadComponents
)
export default enhance(Component)