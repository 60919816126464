import { SET_CONVENTION_BY_HASH, SET_ACKNOWLEDGE_STATUS, SET_URL_ERROR } from "../actions/convention";
import { SET_LOADING_TRUE } from "../actions";

export default (state = {}, action) => {
    switch (action.type) {
        case SET_CONVENTION_BY_HASH:
            return { ...state, convention: action.payload, isLoading: false };
        case SET_LOADING_TRUE:
            return { ...state, isLoading: true };
        case SET_ACKNOWLEDGE_STATUS:
            {
                return { ...state, acknowledge: action.status }
            }
        case SET_URL_ERROR:
            {
                return { ...state, urlError: action.error }
            }
        default:
            return state;
    }
};
