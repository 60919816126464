import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EmailsList from './EmailsList';
import EmailContent from './EmailContent';

const styles = (theme) => ({
    root: {
        width: "80%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        backgroundColor: '#f5f5f5',
    },
    body: {
        fontSize: 18,
        fontWeight: 'bold',
        marginLeft: '2%'
    },
    container: {
        marginTop: theme.spacing.unit * 1
    }
})


const Component = ({ classes, editMode, handleEmail, Email,emailSettings }) => {

    return (
        <Grid item xs={12}>
            <Grid container >
                <Grid item xs={2}>
                    <Grid container>
                        <Paper className={classes.root} elevation={1}>
                            <Grid item xs={12}>
                                <Grid container className={classes.container}>
                                    <Typography className={classes.body}>LIST</Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.container} >
                                <Grid item xs={12}>
                                    <EmailsList handleEmail={handleEmail} editMode={editMode} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <EmailContent editMode={editMode} Email={Email} />

                </Grid>
            </Grid>
        </Grid>
    )
}

const loadComponents = lifecycle({
    componentDidMount() {
        const { actions, isAuthRequired } = this.props
        actions.getEmailSettings(isAuthRequired)

    },

},
);

export const enhance = compose(
    withStyles(styles),
    loadComponents)
export default enhance(Component)