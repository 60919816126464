export const emailsList = [
    { value: "ack-cvn", label: "Send convention", language: "FR" },
    { value: 'ack-cvn', label: "Send convention", language: "EN" },
    { value: "renew-expired-convention", label: "Renewal-Date", language: "FR" },
    { value: "renew-expired-convention", label: "Renewal-Date", language: "EN" },
    { value: "renew-saturated-convention", label: "Renewal-#", language: "FR" },
    { value: "renew-saturated-convention", label: "Renewal-#", language: "EN" },
]

export const listOfFilter = [
    { value: "", label: "All", type: '' },
    { value: "OPEN", label: "Open", type: "status" },
    { value: "CLOSED", label: "Closed", type: "status" },
    { value: "direct", label: "Direct", type: "type" },
    { value: "saas", label: "SaaS", type: "type" },
    { value: "integrator", label: "Integrator", type: "type" },
]

export const country = [
    { value: "england", label: "England", code: 'gb' },
    { value: "france", label: "France", code: 'fr' },
    { value: "germany", label: "Germany", code: 'de' },
    { value: "tunisia", label: "Tunisia", code: 'tn' }
];
export const type = [
    { value: "direct", label: "Direct" },
    { value: "saas", label: "SaaS" },
    { value: "integrator", label: "Integrator" },
];
export const userType = [
    { value: "employee", label: "Employee" },
    { value: "temp", label: "Temp" },
    { value: "client", label: "Client" },
    { value: "supplier", label: "Supplier" }
];
export const language = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" }
];
export const level = [
    { value: 'LEVEL_1', label: "Level 1" },
    { value: 'LEVEL_2', label: "Level 2" },
    { value: 'LEVEL_3', label: "Level 3" }
];
export const statusToFilter = [
    { value: "", label: "All", type: '' },
    { value: "OPEN", label: "Open", type: "account" },
    { value: "CLOSED", label: "Closed", type: "account" },
    { value: "ACTIVE", label: "Active", type: "convention" },
    { value: "PENDING", label: "On Hold", type: "convention" },
    { value: "BLOCKED", label: "Blocked", type: "convention" },
    { value: "SUSPENDED", label: "Suspended", type: "convention" },
    { value: "EXPIRED", label: "Expired", type: "convention" },
    { value: "SATURATED", label: "Saturated", type: "convention" },
];
export const alertStatusToFilter = [
    { value: "", label: "All", },
    { value: "PENDING", label: "Pending" },
    { value: "CLOSED", label: "Closed" },
    { value: "ACTIVE", label: "Active" }

];
export const alertType = [
    { value: "USER_D", label: "User D" },
    { value: "USER_M", label: "User M" },
    { value: "CONVENTION", label: "Convention" },
    { value: "FAMILY", label: "Family" },
    { value: "VOLUME", label: "Volume" },
]
export const alertStatus = [
    { value: "ACTIVE", label: "Active" },
    { value: "PENDING", label: "Pending" },
    { value: "CLOSED", label: "Closed" },


]
export const statusConvention = [
    {value: true, label: 'On'},
    {value: false, label: 'Off'}
]