import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Content } from '../../widgets';
import withRoutes from '../../hoc/routes';
import Reports from '../../components/Reports';
import * as reportsActions from '../../actions/reports'
import { getSortedAccounts, getKpiStatistics } from '../../selectors/reports';

const Page = ({ routes, match, actions, openAccounts,kpiStatistics }) => {
    const route = routes.getRouteByPath(`${match.path}`);
    const isAuthRequired = routes.isAuthRequired(route)
    return (
        <Grid container>
            <Content>
                <Reports actions={actions} isAuthRequired={isAuthRequired} openAccounts={openAccounts} kpiStatistics={kpiStatistics}  />
            </Content>
        </Grid>
    )
}
const actions = {
    getOpenAccounts:reportsActions.getOpenAccounts,
    getKpiStatistics:reportsActions.getKpiStatistics,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
});
const mapStateToProps = createStructuredSelector({
   openAccounts:getSortedAccounts,
   kpiStatistics:getKpiStatistics
})
export const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRoutes)
export default enhance(Page)