import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';

const styles = () => ({
    typography: {
        color: 'red',
        fontWeight: 600,
        fontSize: 14
    },
})
const Component = ({ classes, clientErrors }) => {

    return (
        <Grid container>
            <Grid item xs={10}>
                <Grid container justify="flex-end">
                    <Typography className={classes.typography}>
                        {clientErrors}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

    )
}


export const enhance = compose(withStyles(styles))
export default enhance(Component);