import { loadToken, saveToken } from "../utils/sessionStorage";
import axios from 'axios'
import { CLIENT_ID, CLIENT_SECRET } from "../utils/tokenConfig";
import qs from 'querystring'
import keycloak from '../Keycloak'
import LoadingSpin from "react-loading-spin";
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const setLoadingTrue = () => ({ type: SET_LOADING_TRUE })
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const setLoadingStatus = (status) => ({ type: SET_LOADING_STATUS, status })

export const SET_APPLICATION_LANGUAGE = 'SET_APPLICATION_LANGUAGE';
export const setApplicationLanguage = (language) => ({ type: SET_APPLICATION_LANGUAGE, language })


axios.interceptors.request.use(function (config) {
    if (config.headers.auth === true) {
    //    const token = loadToken().access_token;
        config.headers.Authorization = 'Bearer ' + keycloak.token

    }
    else {
        config.headers.Authorization = "bla";
    }
    return config;
});

const createAxiosResponseInterceptor = () => {
    const params = {
        grant_type: 'refresh_token',
        refresh_token: loadToken().refresh_token
    }
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            // Reject promise if usual error
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            /* 
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);

            return axios.post('/hia-oauth2/oauth/token', qs.stringify(params), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Acces-Control-Allow-Origin': '*'
                },

            }).then(response => {
                // dispatch(setToken(response.data))
                saveToken(response.data)
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return axios(error.response.config);
            }).catch(error => {

                return Promise.reject(error);
            }).finally(createAxiosResponseInterceptor);
        }
    );
}
createAxiosResponseInterceptor();